import React, { useContext, useEffect } from 'react'
import CbmContext from '../Context/Cbm/cbmContext';

function SubmissionSuccess() {

    const cbmContext = useContext(CbmContext);
    const { resetSubmissionStates, submitStatus, submitFailed, submitSuccess } = cbmContext;
    useEffect(() => {
      if (submitFailed !== false || submitStatus !== false || submitSuccess !== false) {
        resetSubmissionStates();
      }
    }, [resetSubmissionStates, submitFailed, submitStatus, submitSuccess])
  return (
    <div className='formContainer'>
        <div className='successBanner'>
            Your submission was sent successfully
        </div>
    </div>
  )
}

export default SubmissionSuccess