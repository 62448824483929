import React from 'react'
import './SubmitBtn.css';

const SubmitBtn = props => {
  return (
    <div id="submitBtn">
        <button type="submit" onClick={(e) => props.onSubmit(e)}>Submit Form</button>
    </div>
  )
}

SubmitBtn.propTypes = {}

export default SubmitBtn