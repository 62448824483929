import React from 'react'

const EmployeeReasonQuit = props => {
  return (
    <fieldset>
        <legend>Employee quit reason:</legend>
        <div className='employeeQuitReason'>
            <textarea className='quitReason' name="quitReason" cols={30} rows={3} onChange={(e) => props.onHandleChange(e)} />
        </div>
    </fieldset>
  )
}

EmployeeReasonQuit.propTypes = {}

export default EmployeeReasonQuit