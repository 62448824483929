import React, { useContext } from 'react'
import CbmContext from '../../Context/Cbm/cbmContext'
import { Navigate } from 'react-router-dom';


function RestrictedRoutes({ children }) {

    const cbmContext = useContext(CbmContext);
    const { auth } = cbmContext;

    return auth ? children : JSON.parse(localStorage.getItem('goToLogout')) === true ? <Navigate to="/logout" /> : <Navigate to="/login" />
}

export default RestrictedRoutes