import React from 'react'
import ReactSignatureCanvas from 'react-signature-canvas';
import './SigPad.css';

const SigPad = ( { sigPad, clearPad }) => {

    return (
        <div className='signature'>
            <label className='required'>Sign</label>
            <ReactSignatureCanvas clearButton="true" penColor='black' canvasProps={{backgroundcolor: 'rgba(192,192,192, 1)', width: 460, height: 50, className: 'sigPad', id: 'sigPad'}} ref={sigPad} />
            <div>
                <button id="sigButton" onClick={e => clearPad(e)} type="button ">Clear</button>
            </div>
        </div>
    )
}

SigPad.propTypes = {}

export default SigPad