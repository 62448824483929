import React, { Fragment, useState } from 'react'
import { faEye, faEyeSlash } from '@fortawesome/free-regular-svg-icons'
import EmployeeInfo from './EmployeeInfo'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const NewEmployeeInfo = props => {

    const [showSSN, setShowSSN] = useState(false);

    const toggleSSN = () => {
        setShowSSN(!showSSN);
    }

  return (
    <Fragment>
        <fieldset>
            <legend>Employee Information</legend>
            <div className='hireStatus'>
                <label className='required'>Hire status</label>
                <div>
                    <label htmlFor='hireStatus'>Hire:</label>
                    <input type="radio" required name='hireType' id='hireStatus' checked={props.newHireInfo.hireType === 'newhire'} value='newhire' onChange={(e) => props.onHandleChange(e)} />
                    <label htmlFor='reHireStatus'>Rehire:</label>
                    <input type="radio" required name='hireType' id='reHireStatus' checked={props.newHireInfo.hireType === 'rehire'} value='rehire' onChange={(e) => props.onHandleChange(e)} />
                </div>
            </div>
            <EmployeeInfo checkInputValid={props.checkInputValid} payload={props.newHireInfo} changeEmpInfo={props.onHandleChange} />
            <div className='newhire-address-info'>
                <div className='address1'>
                    <label htmlFor='address1' className='required'>Address:</label>
                    <input type="text" id="address1" name='address1' value={props.newHireInfo.employee.address.address1} required onChange={(e) => props.handleAddressInfo(e)} />
                </div>
                <div className='address2'>
                    <label htmlFor='address2' className='required'>Apt #:</label>
                    <input type="text" id="address2" name='address2' value={props.newHireInfo.employee.address.address2} onChange={(e) => props.handleAddressInfo(e)} />
                </div>
                <div className='city'>
                    <label htmlFor='city' className='required'>City:</label>
                    <input type="text" name='city' id='city' value={props.newHireInfo.employee.address.city} required onChange={(e) => props.handleAddressInfo(e)} />
                </div>
                <div className='state'>
                    <label htmlFor='state' className='required'>State:</label>
                    <select onChange={(e) => props.handleAddressInfo(e)} value={props.newHireInfo.employee.address.state} required name='state' id='state'>
                        <option key="startOption" value="" name='state'>Select state</option>
                        {props.states?.map((state, index) => {
                            return (
                                <option key={index} value={state?.stateShortName} name='state'>{state?.stateName}</option>
                            )
                        })}
                    </select>
                </div>
                <div className='zip'>
                    <label htmlFor='zip' className='required'>Zip:</label>
                    <input type="text" id="zip" name='zip' value={props.newHireInfo.employee.address.zip} required onChange={(e) => props.handleAddressInfo(e)} />
                </div>
            </div>
            <div className='phone1'>
                <label htmlFor='phone1' className='required'>Phone</label>
                <input type="text" id='phone1' name='phone1' value={props.newHireInfo.employee.phone1} maxLength={10} required pattern='^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$' onChange={(e) => props.onHandleChange(e)} />
            </div>
            <div className='phone2'>
                <label htmlFor='phone2'>Alt. Phone</label>
                <input type="text"  id='phone2' name='phone2' value={props.newHireInfo.employee.phone2} pattern='^(\+0?1\s)?\(?\d{3}\)?[\s.-]\d{3}[\s.-]\d{4}$' onChange={(e) => props.onHandleChange(e)} />
            </div>
            <div className='email'>
                <label htmlFor='email'>E-mail</label>
                <input type="text" id='email' name='email' value={props.newHireInfo.employee.email} autoComplete='true' onChange={(e) => props.onHandleChange(e)} />
            </div>
            <div className='ssn'>
                <label htmlFor='ssn' className='required'>SSN</label>
                <div>
                    <input type={showSSN ? 'text': 'password'} id='ssn' name='ssn' value={props.newHireInfo.employee.ssn} maxLength={9} required onChange={(e) => props.onHandleChange(e)} />
                    <FontAwesomeIcon icon={showSSN ? faEye : faEyeSlash} onClick={() => toggleSSN()} />
                </div>
            </div>
            <div className="content">
                <label htmlFor="dob" className='required'>DOB:</label>
                <input type="date" id="dob" name="dob" value={props.newHireInfo.employee.dob} className="dob" title="Please enter the required information" required onChange={e => props.onHandleChange(e)} />
                </div>
                <div className="content">
                    <label htmlFor="sex" className='required'>Gender</label>
                    <select id="sex" name="gender" value={props.newHireInfo.employee.gender} required className="sex" title="Please enter the required information" onChange={e => props.onHandleChange(e)}>
                        <option value="">Select Gender</option>
                        <option value="Male">Male</option>
                        <option value="Female">Female</option>
                        <option value="Not_Identified">Identifies as another gender</option>
                    </select>
            </div>
      </fieldset>
    </Fragment>
  )
}

NewEmployeeInfo.propTypes = {}

export default NewEmployeeInfo