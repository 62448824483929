import React, { Fragment, useContext, useEffect, useState } from 'react'
import './Pto.css';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import CbmContext from '../../Context/Cbm/cbmContext';
import Comment from '../Comment/Comment';
import { updateEmptyErrorFields, updateErrorFields, removeValidErr, validateData } from '../Utils/helperFuncs';

const Pto = ({ setPayLoad, setFormError }) => {

  const cbmContext = useContext(CbmContext);
  const {  submittedData, errorCode} = cbmContext;
  const [fieldErrors, setFieldErrors] = useState([])
  const [ptoInfo, setptoInfo] = useState({
    employee: {
      employeeNumber: '',
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
    },
    department: '',
    absenceFrom: null,
    absenceTo: null,
    hours: 0,
    comments: '',
    sig: null,
    // portalUser
  })

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])
  
  useEffect(() => {
    setPayLoad(ptoInfo);
  }, [setPayLoad, ptoInfo])
  

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      console.log(JSON.parse(submittedData))
      setptoInfo(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setptoInfo, errorCode])
  const onHandleChange = (e) => {
    const { name, value } = e.target;
    let currentPtoInfo = {...ptoInfo};
    if (name === 'hours') {
      currentPtoInfo.hours = Number(value);
    } else {
      currentPtoInfo[name] = value;
    }
    setptoInfo(currentPtoInfo)
  }

  const checkDataValidation = (name, value, currentData, currentErrorList) => {
    let newErrorList = [];
    if (( value === '' || value === null)) {
      newErrorList = updateEmptyErrorFields(name, currentErrorList);
      console.log(newErrorList);
      setFieldErrors(newErrorList)
      
    } else if (validateData(name, value, currentData) === false) {
      if (fieldErrors?.length > 0) {
        newErrorList = updateErrorFields(name, currentErrorList);
        setFieldErrors(newErrorList)
      } else {
        newErrorList = updateErrorFields(name, []);
        setFieldErrors(newErrorList)
      }
    } else if (validateData(name, value, currentData)){
      setFieldErrors(removeValidErr(name, currentErrorList));
    }
  }

  
  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  const changeEmpInfo = (e) => {
    const { name, value } = e.target;
    let tempPtoInfo = {...ptoInfo};
    // checkDataValidation(name, value, tempPtoInfo, [...fieldErrors])
    tempPtoInfo.employee[name] = value;
    setptoInfo(tempPtoInfo);
  }

  return (
    <Fragment>
      <div className='heading'>
        <h1>Pto</h1>
      </div>
      <fieldset>
        <legend>Employee Info</legend>
        <EmployeeInfo payload={ptoInfo} checkInputValid={checkInputValid} changeEmpInfo={(e) => changeEmpInfo(e)} />
      </fieldset>
      <fieldset>
        <legend>Department Info</legend>
        <div className='departments'>
          <label htmlFor='departments' className='required'>Departments: </label>
          <select id='departments' name='department' className='department-selector' value={ptoInfo.department} required onChange={(e) => onHandleChange(e)}>
            <option value=''>Select department</option>
            <option value='Operations'>Operations</option>
            <option value='Hr'>Human Resources</option>
            <option value='Service'>Service</option>
            <option value='Maintenance'>Maintenance</option>
          </select>
        </div>
      </fieldset>
      <fieldset>
        <legend>Pto Dates</legend>
        <div className='ptoDates'>
          <div className='ptoStartDate'>
            <label htmlFor='startDate' class='required'>Absence from: </label>
            <input type='date' id='startDate' name='absenceFrom' required onChange={(e) => onHandleChange(e)}/>
          </div>
          <div className='ptoEndDate'>
            <label htmlFor='endDate' class='required'>Absence to: </label>
            <input type='date' id='endDate' name='absenceTo' required onChange={(e) => onHandleChange(e)}/>
          </div>
        </div>
      </fieldset>
      <fieldset>
        <legend>Pto hours</legend>
        <div className='ptoHours'>
          <label htmlFor='ptoHours' class='required'>Enter Pto hours requested:</label>
          <input type="number" id='ptoHours' name='hours' required onChange={(e) => onHandleChange(e)}/>
        </div>
      </fieldset>
      <fieldset className='ptoComments'>
        <legend>Pto Comments</legend>
        <Comment comments={'ptoCommentsId'} payload={ptoInfo.comments} onHandleChange={(e) => onHandleChange(e)} />
      </fieldset>
      </Fragment>
  )
}
// eslint-disable-next-line
Pto.propTypes = {}

export default Pto
