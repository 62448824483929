import React, { Fragment } from 'react'
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo'
import EmployeeReasonQuit from '../TermComponents/ReasonForQuit/EmployeeReasonQuit'

const Quit = props => {
  return (
    <Fragment>
      <div className='heading'>
        <h1>Quit Form</h1>
      </div>
      <fieldset>
        <legend>Employee Info</legend>
        <EmployeeInfo payload={props.termInfo} checkInputValid={props.checkInputValid} changeEmpInfo={props.changeEmpInfo} />
      </fieldset>
      <fieldset className='employeeStatusInfo'>
        <legend>Employee status info</legend>
        <div className='employeeStatus'> 
          <div className='lastDayWorked'>
            <label className='required'>Last day worked:</label>
            <input type="date" name={'lastDayWorked'} value={props.termInfo.lastDayWorked} onChange={(e) => props.onHandleChange(e)} />
          </div>
              <div className='twoWeeksGiven'>
                <label className='required'>Was two weeks given?</label>
                <div>
                  <label htmlFor='twoWeeksYes'>Yes</label>
                  <input type="radio" name="twoWeeksGiven" value={true} className="yesOption" id="optionYes" onChange={(e) => props.onHandleChange(e)} />
                </div>
                <div>
                  <label htmlFor='twoWeeksNo'>No</label>
                  <input type="radio" name="twoWeeksGiven" value={false} className="noOption" id="optionNo" onChange={(e) => props.onHandleChange(e)} />
                </div>
              </div>
              <div className='rehireStatus'>
                <label className='required'>Eligible for rehire?</label>
              <div>
                  <label htmlFor='rehireYes'>Yes</label>
                  <input type="radio" name="eligibleRehire" value={true} className="yesOption" id="optionYes" onChange={(e) => props.onHandleChange(e)} />
                </div>
                <div>
                  <label htmlFor='rehireNo'>No</label>
                  <input type="radio" name="eligibleRehire" value={false} className="noOption" id="optionNo" onChange={(e) => props.onHandleChange(e)} />
                </div>
              </div>
            </div>
      </fieldset>
      <EmployeeReasonQuit onHandleChange={props.onHandleChange} />
    </Fragment>
  )
}
// eslint-disable-next-line
Quit.propTypes = {}

export default Quit