import {
    GET_USER,
    GET_STORES,
    GET_AUTH,
    AUTH_ERROR,
    LOGIN_ERROR,
    GET_LOGOUT,
    ERROR_STORES,
    ERROR_STATES,
    CLEAR_ERRORS,
    SET_LOADING,
    FORM_SUBMISSION,
    FORM_SUCCESS,
    HEADER_LINKS,
    TERM_TYPES,
    GET_PS,
    GET_TARGET_ITEMS,
    RESET_FORM_SUBMISSION_STATES,
    SET_SUBMIT_FAILED,
    SET_AUTH_LOADING,
    SET_STORE_LOADING,
    GET_US_STATES,
} from '../Types';

const CbmReducer = (state, action) => {
    switch(action.type) {

        case AUTH_ERROR:
        case LOGIN_ERROR:
        case GET_LOGOUT:
            // localStorage.removeItem('token');
            return {
                ...state,
                errorMessage: action.errorMessage,
                errorCode: action.errorCode,
                loginErrorMsg: action.loginErrorMsg,
                isAuthenticated: false,
                user: null,
                authLoading: false,
                token: null,
                stores: '',
                usstates: '',
                logoutMsg: action.logoutMsg,
                logoutType: action.logoutType
            }
            case GET_AUTH:
                return {
                    ...state,
                    user: action.user,
                    isAuthenticated: action.payload,
                    auth: action.auth,
                    authLoading: false,
                    portalUser: action.portalUser
                }
            case GET_USER:
                localStorage.setItem('auth', action.payload);
                return {
                    ...state,
                    auth: action.payload,
                    user: action.user,
                    isAuthenticated: true,
                    authLoading: false,
                    portalUser: action.portalUser
                }
            case HEADER_LINKS:
                return {
                    ...state,
                    headerLinks: action.payload
                }
            case FORM_SUBMISSION:
                return {
                    ...state,
                    successMessage: action.successMessage,
                    submitStatus: action.submitStatus,
                    submitSuccess: action.submitSuccess,
                    errorCode: '',
                    errorMessage: action.errorMessage,
                    success: action.success,
                    loading: action.loading
                }
        case GET_TARGET_ITEMS:
            return {
                ...state,
                targetOrderItems: action.payload,
                loading: false,
            }
        case GET_STORES:
            return {
                ...state,
                stores: action.payload,
                storeLoading: false
            }
        case GET_US_STATES:
            return {
                ...state,
                stateList: action.payload,
                stateListRetrieved: action.stateListRetrieved,
                loading: action.loading,
            }
        case ERROR_STORES:
        case ERROR_STATES:
            return {
                ...state,
                errorMessage: action.errorMessage,
                errorCode: action.errorCode,
                submitFailed: action.submitFailed,
                submitStatus: action.submitStatus,
                retrieveStores: action.retrieveStores,
                submittedData: action.submittedData,
                loading: false,
                logoutType: action.logoutType,
                logoutMsg: action.logoutMsg,
            }
        case CLEAR_ERRORS:
            return {
                ...state,
                errorMessage: null,
            }
        case SET_LOADING:
        return {
                ...state,
                loading: true,
        }
        case SET_AUTH_LOADING:
            return {
                    ...state,
                    authLoading: true,
            }
        case SET_STORE_LOADING:
            return {
                    ...state,
                    storeLoading: true,
            }
        case FORM_SUCCESS:
            return {
                    ...state,
                    successMessage: null,
                    success: false,
            }
        case TERM_TYPES:
            return {
                    ...state,
                    successMessage: null,
                    success: false,
                    termType: action.payload
            }
        case GET_PS:
            return {
                ...state,
                successMessage: action.successMessage,
                loading: false,
                psListRetrieved: action.psListRetrieved,
                psList: action.payload
            }
        case RESET_FORM_SUBMISSION_STATES:
            return {
                ...state,
                submitSuccess: action.submitSuccess,
                submitFailed: action.submitFailed,
                submitStatus: action.submitStatus
            }
        case SET_SUBMIT_FAILED:
            return {
                ...state,
                submitFailed: action.submitFailed
            }
        default: 
        return state;
    }
}
export default CbmReducer;