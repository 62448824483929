import React, { Fragment } from 'react'

import './EmployeeInfo.css';

const EmployeeInfo = props => {
  return (
    <Fragment>
        { window.location.href.indexOf('newHire') === -1 &&
        <div className='employeeNum'>
        <label htmlFor="empNum" className='required'>Employee Num:</label>
                <input type="number" id="empNum" name='employeeNumber' value={props.payload?.employee?.employeeNumber} required className='empNum' onChange={(e) => props.changeEmpInfo(e)} />
        </div>
        }
        <div className='employeeName'>
            <label htmlFor="empFName" className='required'>Employee First Name:</label>
            <input type="text" id="empFName" name='employeeFirst' value={props.payload?.employee?.employeeFirst} required className={props?.checkInputValid('employeeFirst') ? 'empName' : 'empName input-invalid'} onChange={(e) => props.changeEmpInfo(e)} />
        </div>
        <div className='employeeName'>
            <label htmlFor="empMName">Employee Middle Name:</label>
            <input type="text" id="empMName" name='employeeMiddle' value={props.payload?.employee?.employeeMiddle} className={props?.checkInputValid('employeeMiddle') ? 'empName' : 'empName input-invalid'} onChange={(e) => props.changeEmpInfo(e)} />
        </div>
        <div className='employeeName'>
            <label htmlFor="empLName" className='required'>Employee Last Name:</label>
            <input type="text" id="empLName" name='employeeLast' value={props.payload?.employee?.employeeLast} required className={props?.checkInputValid('employeeLast') ? 'empName' : 'empName input-invalid'} onChange={(e) => props.changeEmpInfo(e)} />
        </div>
        <div className='employeeName'>
            <label htmlFor="empSLName">Employee Second Last Name:</label>
            <input type="text" id="empSLName" name='employeeSecondLast' value={props.payload?.employee?.employeeSecondLast} className={props?.checkInputValid('employeeSecondLast') ? 'empName' : 'empName input-invalid'} onChange={(e) => props.changeEmpInfo(e)} />
        </div>
    </Fragment>
  )
}

EmployeeInfo.propTypes = {} 

export default EmployeeInfo