

export const GET_AUTH = 'GET_AUTH';
export const AUTH_ERROR = 'AUTH_ERROR';
export const LOGIN_ERROR = 'LOGIN_ERROR';
export const GET_LOGOUT = 'GET_LOGOUT';
export const CLEAR_ERRORS = 'CLEAR_ERRORS';
export const ERROR_STATES = 'ERROR_STATES';
export const FORM_SUBMISSION = 'FORM_SUBMISSION';
export const SET_LOADING = 'SET_LOADING';
export const SET_STORE_LOADING = 'SET_STORE_LOADING';
export const SET_AUTH_LOADING = 'SET_AUTH_LOADING';
export const GET_STORES = 'GET_STORES';
export const ERROR_STORES = 'ERROR_STORES';
export const FORM_SUCCESS = 'FORM_SUCCESS';
export const GET_USER = 'GET_USER';
export const HEADER_LINKS = 'HEADER_LINKS';
export const TERM_TYPES = 'TERM_TYPES';
export const GET_PS = 'GET_PS';
export const GET_TARGET_ITEMS = 'GET_TARGET_ITEMS';
export const RESET_FORM_SUBMISSION_STATES = 'RESET_FORM_SUBMISSION_STATES';
export const SET_SUBMIT_FAILED = 'SET_SUBMIT_FAILED';
export const SET_SUBMIT_SUCCESS = 'SET_SUBMIT_SUCCESS';
export const SET_SUBMIT_STATUS = 'SET_SUBMIT_STATUS';
export const GET_US_STATES = 'GET_US_STATES';
