import React from 'react'

import './Comment.css';

const Comment = props => {
  return (
    <div className='reasonMissed'>
        <label htmlFor={props.commentsId}>Comments:</label>
        <textarea id={props.commentsId} name='comments' value={props.payload} className='reasonMissedText' cols='30' rows='3'  onChange={(e) => props.onHandleChange(e)} />
  </div>
  )
}

Comment.propTypes = {}

export default Comment