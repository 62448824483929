import React, { Fragment } from 'react'
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo'

const NoCallNoShowFirst = props => {
  return (
    <Fragment>
      <div className='heading'>
        <h1>No call no show Form</h1>
      </div>
      <fieldset>
        <legend>Employee Info</legend>
        <EmployeeInfo payload={props.termInfo}s checkInputValid={props.checkInputValid} changeEmpInfo={props.changeEmpInfo} />
      </fieldset>
      <fieldset className='employeeStatusInfo'>
        <legend>Employee status info</legend>
        <div className='lastDayWorked'>
          <label className='required'>Last day worked:</label>
          <input type="date" name={'lastDayWorked'} value={props.termInfo.lastDayWorked} onChange={(e) => props.onHandleChange(e)} />
        </div>
        <div className='statusInfo'>
          <div className='rehireStatus'>
            <label className='required'>Eligible for rehire?</label>
          <div>
          <label htmlFor='rehireYes'>Yes</label>
              <input type="radio" name="eligibleRehire" value={true} className="yesOption" id="optionYes" onChange={(e) => props.onHandleChange(e)} />
            </div>
            <div>
              <label htmlFor='rehireNo'>No</label>
              <input type="radio" name="eligibleRehire" value={false} className="noOption" id="optionNo" onChange={(e) => props.onHandleChange(e)} />
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}

NoCallNoShowFirst.propTypes = {}

export default NoCallNoShowFirst