import React from 'react'
import './Footer.css';

const Footer = props => {
  return (
    <div className='footerContainer'>
      <div className='office-location-container'>
        <div className='office-location-info'>
          <p>Office Lcation</p>
          <p>1857 Buerkle Rd</p>
          <p>White Bear Lake, MN 55128</p>
          <p>651-481-9970</p>
        </div>
      </div>
      <div className='contact-method-container'>
        <div className='phone-contact'>
          <h3>Phone</h3>
          <p>HR - 651-481-9970 Ext. 973 </p>
          <p>Payroll - 651-481-9970 Ext. 977</p>
          <p>Operations - 651-481-9970 Ext. 975</p>
        </div>
        <div className='email-contact'>
          <h3>Email</h3>
          <p>HR - <a href="mailto:hr@carlsonbuilding.com">hr@carlsonbuilding.com</a></p>
          <p>Payroll - <a href="mailto:payroll@carlsonbuilding.com">payroll@carlsonbuilding.com</a></p>
          <p>Operations - <a href="mailto:ops@carlsonbuilding.com">ops@carlsonbuilding.com</a></p>
        </div>
      </div>
      <div>

      </div>
    </div>
  )
}

Footer.propTypes = {}

export default Footer