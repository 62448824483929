import React, { useContext, useState, useEffect, Fragment } from 'react'
import Store from '../Store/Store'
import './WorkTicket.css';
import Comment from '../Comment/Comment';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import CbmContext from '../../Context/Cbm/cbmContext';
import { updateEmptyErrorFields, updateErrorFields, removeValidErr, validateData } from '../Utils/helperFuncs';

const WorkTicket = ({ setPayLoad, setFormError }) => {

  const cbmContext = useContext(CbmContext);
  const { stores, portalUser, submittedData, errorCode } = cbmContext;
  const [fieldErrors, setFieldErrors] = useState([]);
  const [workTicketData, setWorkTicketData] = useState({
    employee: {
      employeeNumber: '',
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
    },
    notes: '',
    equipmentNeeded: '',
    currentLocation: null,
    projectLocation: null,
    typeOfWork: '',
    isBillable: '',
    supplyOrderDate: null,
    projectStartDate: null,
    projectEndDate: null,
    orderNumber: '',
    supplyOrderSubmitted: false,
    portalUser
  })
  
  useEffect(() => {
    setPayLoad(workTicketData);
  }, [setPayLoad, workTicketData])

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])

  const checkDataValidation = (name, value, currentData, currentErrorList) => {
    let newErrorList = [];
    if (( value === '' || value === null)) {
      newErrorList = updateEmptyErrorFields(name, currentErrorList);
      console.log(newErrorList);
      setFieldErrors(newErrorList)
      
    } else if (validateData(name, value, currentData) === false) {
      if (fieldErrors?.length > 0) {
        newErrorList = updateErrorFields(name, currentErrorList);
        setFieldErrors(newErrorList)
      } else {
        newErrorList = updateErrorFields(name, []);
        console.log(newErrorList);
        setFieldErrors(newErrorList)
      }
    } else if (validateData(name, value, currentData)){
      setFieldErrors(removeValidErr(name, currentErrorList));
    }
  }

  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  const changeEmpInfo = (e) => {
    const { name, value } = e.target;
    let tempWorkTicketData = {...workTicketData};
    // checkDataValidation(name, value, tempWorkTicketData, [...fieldErrors])
    tempWorkTicketData.employee[name] = value;
    setWorkTicketData(tempWorkTicketData);
  }

  const handleComments = (e, commentType) => {
    const { value } = e.target;
    setWorkTicketData({...workTicketData, [commentType]:value })
  }

  const changeProjectLocation = (e) => {
    const {value} = e.target;
    const foundStore = stores.filter(store => {
      return parseInt(value) === store.id;
    })
    setWorkTicketData({...workTicketData, projectLocation: foundStore[0]});
  }

  const changeCurrentLocation = (e) => {
    const {value} = e.target;
    const foundStore = stores.filter(store => {
      return parseInt(value) === store.id;
    })
    setWorkTicketData({...workTicketData, currentLocation: foundStore[0]});
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      console.log(JSON.parse(submittedData))
      setWorkTicketData(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setWorkTicketData, errorCode])
  return (
    <Fragment>
      <div className='heading'>
        <h1>Work Ticket</h1>
      </div>
      <fieldset>
        <legend>Employee Info</legend>
        <EmployeeInfo payload={workTicketData} checkInputValid={checkInputValid} changeEmpInfo={changeEmpInfo}/>
      </fieldset>
    <fieldset className='typeOfWork'>
      <legend>Type of Work</legend>
        <div>
          <div className='workType'>
            <label htmlFor='workTypeId' className='required'>Work type: </label>
            <select id='workTypeId' value={workTicketData.typeOfWork} onChange={(e) => setWorkTicketData({...workTicketData, typeOfWork: e.target.value})}>
              <option name='default' value=''>Choose from list</option>
              <option name='annualStrip' value='Annual_Strip'>Annual Strip</option>
              <option name='partialStrip' value='Partial_Strip'>Partial Strip</option>
              <option name='fullExtraction' value='Full_Extraction'>Full Extraction</option>
              <option name='storeRemodel' value='Store_Remodel'>Store Remodel</option>
              <option name='officeRecoat' value='Office_Recoat'>Office Recoat</option>
              <option name='deepScrub' value='Deep_Scrub_And_Recoat'>Deep Scrub And Recoat</option>
              <option name='bounceBack' value='AnBounce_Backnual_Strip'>Bounce Back</option>
              <option name='fouthQuarterPrep' value='Fourth_Quarter_Prep'>4th Quarter Prep</option>
            </select>
          </div>
          <div className='billable'>
            <label htmlFor='billingTypeId' className='required'>Billable/Non-Billable </label>
            <select id='billingTypeId' value={workTicketData.isBillable} onChange={(e) => setWorkTicketData({...workTicketData, isBillable: e.target.value})}>
              <option name='default' value=''>Choose from list</option>
              <option name='billable' value='Billable'>Billable</option>
              <option name='nonBill' value='Non-Billable'>Non-Billable</option>
            </select>
          </div>
        </div>
    </fieldset>
    <fieldset className='specialNotes'>
      <legend>Notes</legend>
      <div className='comments'>
        <Comment payload={workTicketData.notes} commentsId={'specialNotesId'} onHandleChange={(e) => handleComments(e, 'notes')} />
      </div>
    </fieldset>
    <fieldset className='equipmentNeeded'>
      <legend>Equipment</legend>
      <div className='comments'>
        <Comment payload={workTicketData.equipmentNeeded} commentsId={'equipmentNeededId'} onHandleChange={(e) => handleComments(e, 'equipmentNeeded')} />
      </div>
    </fieldset>
    <fieldset className='wtContainer'>
      <legend>Store Info</legend>
      <Store storeId={'projectLocationId'} storeValue={workTicketData.projectLocation?.id} changeStoreInfo={changeProjectLocation} storeList={stores} header={'Project Location:'} />
      <br />
      <br />
      <Store storeId={'currentLocationId'} storeValue={workTicketData.currentLocation?.id} changeStoreInfo={changeCurrentLocation} storeList={stores} header={'Current Location:'} />
    </fieldset>
    <fieldset className='supplyOrderStatus'>
      <legend>Supply order status</legend>
      <div className='supplyOrder'>
        <label className='required'>Supply order submitted: </label>
        <div onChange={(e) => setWorkTicketData({...workTicketData, supplyOrderSubmitted: e.target.value})}>
          Yes <input type="radio" checked={workTicketData.supplyOrderSubmitted === "true"} value={true} name="supplySubmitted" /> No <input type="radio" checked={workTicketData.supplyOrderSubmitted === "false"} value={false} name="supplySubmitted" />
        </div>
      </div>
      <div className='supplyOrderInfo'>
        <div className='orderDate'>
          <label htmlFor='supplyOrderDateId' className='required'>Supply order date:</label>
          <input type="date" id='supplyOrderDateId' onChange={(e) => setWorkTicketData({...workTicketData, supplyOrderDate: e.target.value})}  />
        </div>
        <div className='orderNumber'>
          <label htmlFor='supplyOrderNumId' className='required'>Supply order number:</label>
          <input type="text" id='supplyOrderNumId' onChange={(e) => setWorkTicketData({...workTicketData, orderNumber: e.target.value})} />
        </div>
      </div>
    </fieldset>
    <fieldset className='projectInfo'>
      <legend>Project Info</legend>
      <div className='projectDates'>
        <div className='projectStartDate'>
          <label htmlFor='projectStartDateId' className='required'>Project start date:</label>
          <input type="date" id='projectStartDateId' onChange={(e) => setWorkTicketData({...workTicketData, projectStartDate: e.target.value})}  />
        </div>
        <div className='projectEndDate'>
          <label htmlFor='projectEndDateId' className='required'>Project end date:</label>
          <input type="date" id='projectEndDateId' onChange={(e) => setWorkTicketData({...workTicketData, projectEndDate: e.target.value})}  />
        </div>
      </div>
    </fieldset>
  </Fragment>
  )
}
// eslint-disable-next-line
WorkTicket.propTypes = {}

export default WorkTicket
