import React, { Fragment, useCallback, useContext, useEffect, useRef, useState } from 'react'
import CbmContext from '../Context/Cbm/cbmContext';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { renderComponentByFormName, formDepartment, showManagerSigPad } from '../Components/Utils/helperFuncs';
import SubmitBtn from '../Components/SubmitButton/SubmitBtn';
import SigPad from '../Components/Signature/SigPad';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';

function Home() {
  const navigate = useNavigate();
  const [queryParams, ] = useSearchParams();
  const [formName, setFormName ] = useState(queryParams.get('formName'));
  const [payload, setPayload] = useState(null);
  const [idBadgeFile, setidBadgeFile] = useState(null);
  const [govIdFront, setGovIdFront] = useState(null);
  const [govIdRear, setGovIdRear] = useState(null);
  const [ssnCard, setSsnCard] = useState(null);
  const cbmContext = useContext(CbmContext);
  const { portalUser, auth, validateToken, formSubmit, 
          loading, storeLoading, authLoading,
          errorCode, submitStatus, submitFailed, 
          submitSuccess, getStores, stores,retrieveStores,
          errorMessage, resetSubmissionStates } = cbmContext;
  const [ formError, setFormError] = useState(false);
  const [errMsg, setErrMsg] = useState('');
  const empPad = useRef({});
  const employeePad = useRef({});
  const clearPad = (e) => {
    e.preventDefault();
    empPad.current.clear();
}

const clearEmployeePad = (e) => {
  e.preventDefault();
  employeePad.current.clear();
}

const formatErrMsgs = (err) => {
  if (err === 'temporal' || (err.indexOf('Temporal') > -1 && err.indexOf('null') > -1)) {
    return 'Please fill in any missing date fields.'
  } else if (err.indexOf('Department') > -1 && err.indexOf('Coercion') > -1) {
    return 'Please select a department.'
  } else if (err.indexOf('Employee') > -1 && err.indexOf('null') > -1) {
    return 'Please fill out employee number, first name, or last name';
  } else if (err.indexOf('Store') > -1 && err.indexOf('null') > -1) {
    return 'Please select any locations you missed.'
  } else if (err.indexOf('Size') > -1 && err.indexOf('Coercion') > -1) {
    return 'Please select a shirt size';
  } else if (err.indexOf('TypeOfWork') > -1 && err.indexOf('Coercion') > -1) {
    return 'Please select a type of work'
  } else if (err.indexOf('null') > -1) {
    return 'Please fill in missing fields with red *'
  }
}

const validationCheck = useCallback(() => {
  if (formError) {
    return false;
  } else if ((errorCode !== '' && errorCode !== null)) {
    if (errorCode === 400 && !errorMessage.errorMessage && submitFailed && submitStatus) {
      setErrMsg(formatErrMsgs(errorMessage))
      return false;
    } else if (errorCode === 400 && errorMessage.errorMessage && submitFailed && submitStatus) {
      setErrMsg(errorMessage.errorMessage)
      return false;
    } else if (errorCode === 500 && submitFailed && submitStatus) {
      setErrMsg(formatErrMsgs(errorMessage));
      return false;
    } else if (errorCode === 200 && submitFailed && submitStatus) {
      setErrMsg(errorMessage);
    } 
  }
  return true;
}, [errorCode, submitFailed, submitStatus, errorMessage, formError])

  useEffect(() => {
    if (auth && !authLoading)
    validateToken(auth);
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (queryParams.get('formName') !== null)
    setFormName(queryParams.get('formName'));
  }, [queryParams])

  useEffect(() => {
    if (submitStatus && submitSuccess) {
      console.log('Navingating to success page');
      navigate("/success", { state: { payload: payload } })
    }
  }, [submitStatus, submitSuccess, navigate, payload])

  useEffect(() => {
    if (!storeLoading) {
      if (portalUser && stores.length === 0) {
        if (portalUser?.district && retrieveStores !== false) {
        getStores(portalUser.district);
        }
      }
    }
    // eslint-disable-next-line
  }, [getStores, stores, portalUser?.district])

  useEffect(() => {
    if (errorCode !== '' && errorCode !== null && submitFailed) {
      validationCheck();
    }
  }, [validationCheck, errorCode, submitFailed])

  const processNewHirePayload = () => {
    let formdata = new FormData();
    let blob = new Blob([JSON.stringify(payload)], {type: 'application/json'})  
    formdata.append('newHireInfoJSON', blob, 'newHireInfo');
    if (idBadgeFile !== null) {
      formdata.append('idBadge', idBadgeFile, 'idBadge');
    }  else {setErrMsg('Please select an id badge file.')}
    if (govIdFront !== null) {
      formdata.append('govIdFront', govIdFront, 'govIdFront');
    }  else {setErrMsg('Please upload front side of gov id card.')}
    if (govIdRear !== null) {
      formdata.append('govIdRear', govIdRear, 'govIdRear');
    } else {setErrMsg('Please upload read side of gov id card.')} 
    if (ssnCard !== null) {
      formdata.append('ssnCard', ssnCard, 'ssnCard');
    } else {setErrMsg('Please upload ssn card.')}
    return formdata;
  }

  const onSubmit = async (e) => {
    e.preventDefault();
    if (submitFailed || submitStatus || submitSuccess) {
      resetSubmissionStates();
    }
    let finalPayload = {...payload, portalUser: portalUser}
    if (!(formName === 'workTicket' || formName === 'termination' || formName === 'perDiem' || formName === 'mileage' || formName === 'nonMnMileage' || formName === 'hotel' || formName === 'newHire')) {
      finalPayload = {...finalPayload, sig: empPad.current.getTrimmedCanvas().toDataURL('image/png')}
    }
    if (formName === 'timeAdjustment') {
      finalPayload = {...finalPayload, managerSig: empPad.current.getTrimmedCanvas().toDataURL('image/png')}
      finalPayload = {...finalPayload, employeeSig: employeePad.current.getTrimmedCanvas().toDataURL('image/png')}
    }
    if (validationCheck()) {
      setErrMsg('');
      let formData = (formName === 'newHire') && processNewHirePayload();
      if ( submitStatus === false && formError === false) {
        if (formName !== 'newHire') {
          await formSubmit(finalPayload, formName, formDepartment(formName))
        } else {
          await formSubmit(formData, formName, formDepartment(formName))
        }
      }
    } else {
      window.scrollTo({ left: 0, top: 0, behavior: 'smooth'})
    }
  }
  return (
    <div className='container'>
      {(loading || storeLoading || authLoading) ?
        <FontAwesomeIcon className="loading-spinner" icon={faSpinner} spin size="2xl" />
        :
        <div className='formContainer'>
            { formName === null ?
            <h3>{`Welcome ${portalUser?.userFirst} ${portalUser?.userLast}`}</h3>
            :
            <Fragment>
              {errMsg !== '' && <div className='errMsg'><FontAwesomeIcon icon={faCircleExclamation} style={{ color: '#fc0303'}}/><p>{errMsg}</p></div>}
              {renderComponentByFormName(
                formName, 
                payload, 
                setPayload, 
                submitSuccess, 
                submitFailed, 
                submitSuccess, 
                setidBadgeFile, 
                setGovIdFront, 
                setGovIdRear, 
                setSsnCard, 
                formError, 
                setFormError
                )}
            </Fragment>
            }
            { formName === 'timeAdjustment' &&
            <fieldset className='employeeSig'>
              <legend>Employee Signature</legend>
              <div className="signature">
              <SigPad sigPad={employeePad} clearPad={(e) => clearEmployeePad(e)}/>
              </div>
            </fieldset>
            }
            { showManagerSigPad(formName) &&
            <fieldset className='employeeSig'>
              <legend>Manager Signature</legend>
              <div className="signature">
                <SigPad sigPad={empPad} clearPad={(e) => clearPad(e)}/>
              </div>
            </fieldset>
            }
            { formName !== null &&
          <div style={{ width: '100%', marginTop: '1rem'}}>
            <SubmitBtn onSubmit={onSubmit} />
          </div>
          }
        </div>
      }
    </div>
  )
}

export default Home