import React, { useCallback, useReducer } from 'react';
import { Navigate, redirect, useNavigate } from 'react-router-dom';
import axios from 'axios';
import CbmContext from './cbmContext';
import cbmReducer from './cbmReducer';

import {
    ERROR_STATES,
    GET_PS,
    GET_LOGOUT,
    GET_STORES,
    ERROR_STORES,
    CLEAR_ERRORS,
    SET_LOADING,
    FORM_SUBMISSION,
    FORM_SUCCESS,
    TERM_TYPES,
    GET_AUTH,
    AUTH_ERROR,
    GET_USER,
    LOGIN_ERROR,
    GET_TARGET_ITEMS,
    RESET_FORM_SUBMISSION_STATES,
    SET_SUBMIT_FAILED,
    SET_STORE_LOADING,
    SET_AUTH_LOADING,
    GET_US_STATES,
} from '../Types';
import { urlBuilding } from '../../config/apiConfig';

const CbmState = props => {
    const backendUrl = window.location.hostname.indexOf('stage') > -1 ? urlBuilding.stageUrl : urlBuilding.localUrl;
    const initialState = {
        stores: [],
        stateList: [],
        targetOrderItems: [],
        auth: localStorage.getItem('auth'),
        user: {},
        psList: [],
        isAuthenticated: false,
        errorMessage: '',
        errorCode: '',
        loginErrorMsg: '',
        submitStatus: false,
        submitFailed: false,
        submitSuccess: false,
        submittedData: null,
        retrieveStores: true,
        stateListRetrieved: false,
        psListRetrieved: false,
        loading: false,
        storeLoading: false,
        authLoading: false,
        cities: '',
        state: '',
        termType: null,
        success: false,
        logoutMsg: '',
        logoutType: '',
        headerLinks: [{
            link: '/?formName=backPay',
            title: 'BackPay'
        },
        {
            link: '/?formName=bonus',
            title: 'Bonus'
        },
        {
            link: '/?formName=hotel',
            title: 'Hotel'
        },
        {
            link: '/?formName=mileage',
            title: 'Mileage'
        },
        {
            link: '/?formName=nonMnMileage',
            title: 'Non MN Mileage'
        },
        {
            link: '/?formName=newHire',
            title: 'NewHire'
        },
        {
            link: '/?formName=perDiem',
            title: 'Perdiem'
        },
        {
            link: '/?formName=pto',
            title: 'Pto'
        },
        {
            link: '/?formName=targetOrder',
            title: 'Target Order'
        },
        {
            link: '/?formName=termination',
            title: 'Termination'
        },
        {
            link: '/?formName=timeAdjustment',
            title: 'Time Adjustment'
        },
        {
            link: '/?formName=uniform',
            title: 'Uniform'
        },
        {
            link: '/?formName=workTicket',
            title: 'Work Ticket'
        }],
        portalUser: null
    }

const [state, dispatch] = useReducer(cbmReducer, initialState);

    const navigation = useNavigate();

    const clearErrors = () => {
        setTimeout(() => dispatch({ type: CLEAR_ERRORS }), 3000);
    }

    const clearSuccess = () => {
        setTimeout(() => dispatch({ type: FORM_SUCCESS }),3000);
    }

    const setTermType = (termType) => {
        dispatch({
            type: TERM_TYPES,
            payload: termType
        })
    }

    const getPS = async () => {
        try {
            const res = await axios.get(`${backendUrl}/api/v1/ops/getPSByPortalUserId/${state?.portalUser?.id}`, 
                            {              
                                headers: {
                                    "Authorization": 'Bearer ' + state?.auth
                            }})
            if (res.status === 200) {
                dispatch({ 
                    type: GET_PS,
                    payload: res.data,
                    psListRetrieved: true,
                    successMessage: 'PS list retrieved successfully.'
                })
            }
        } catch(err) {
            console.log(err);
            if (err.request.status === 401 || err.request.status === 403) {
                dispatch({
                    type: GET_LOGOUT,
                    auth: null,
                    logoutType: 'timed-out',
                    logoutMsg: 'Your session has timed out'
                });
                <Navigate to="/logout" />
            } else {
                dispatch({
                    type: ERROR_STORES,
                    payload: err.response.data.msg
                })
            }
        }
    }

    const getStates = async() => {
        setLoading();
        try {
        const res = await axios.get(`${backendUrl}/api/v1/statecity/getStates`,
                    {          
                        headers: {
                            "Authorization": 'Bearer ' + state.auth
                    }})
                    console.log(res.data);
            if (res.data) {
                dispatch({
                    type: GET_US_STATES,
                    payload: res.data,
                    stateListRetrieved: true,
                    loading: false,
                })
            }
        } catch(err) {
            console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                console.log('Unauthorized')
                dispatch({
                    type: GET_LOGOUT,
                    auth: null,
                    logoutType: 'timed-out',
                    logoutMsg: 'Your session has timed out'
                });
                navigation("/logout")
            } else {
                dispatch({
                    type: ERROR_STORES,
                    errorMessage: err.message,
                    errorCode: err.response.status,
                    retrieveStores: false,
                })
            }
        }
    }

    const getStores = useCallback(async(district) => {

        setStoreLoading();
        try {
        const res = await axios.post(`${backendUrl}/api/stores/v1/getDistrictStores`, district,
                    {          
                        headers: {
                            "Authorization": 'Bearer ' + state.auth
                    }})
                    console.log(res.data);
            if (res.data) {
                dispatch({
                    type: GET_STORES,
                    payload: res.data,
                })
            }
        } catch(err) {
            console.log(err);
            if (err.response.status === 401 || err.response.status === 403) {
                console.log('Unauthorized')
                dispatch({
                    type: GET_LOGOUT,
                    auth: null,
                    logoutType: 'timed-out',
                    logoutMsg: 'Your session has timed out'
                });
                navigation("/logout")
            } else {
                dispatch({
                    type: ERROR_STORES,
                    errorMessage: err.message,
                    errorCode: err.response.status,
                    retrieveStores: false,
                })
            }
        }
    }, [backendUrl, navigation, state.auth])

    const getTargetItems = async () => {
        const items = await axios.get(`${backendUrl}/api/v1/ops/getTargetItems`, {
          headers: {
              "Authorization": 'Bearer ' + state.auth
          }
        });
        if (items.data.targetItems !== null) {
          dispatch({ 
            type: GET_TARGET_ITEMS,
            payload: items.data?.targetItems
          })
        }
      }
    const logout = () => {
        dispatch({
            type: GET_LOGOUT,
            auth: null,
            logoutType: 'user logged out',
            logoutMsg: 'You have logged out successfully.'
        });
        <Navigate to="/logout" />
    }
//Get User
    const validateToken = useCallback(async (token) => {
        setAuthLoading();
    try {
        axios.get(`${backendUrl}/api/v1/auth/validateToken?token=${token}`)
            .then(async(res) => {
                if (res.data.jwtStatus === true) {
                    dispatch({
                        type: GET_AUTH,
                        payload: res.data.jwtStatus,
                        auth: res.data.jwt,
                        user: res.data.user,
                        portalUser: res.data.user
                
                    });
                } else {
                    localStorage.removeItem('auth');
                    console.log('Removed auth token')
                    dispatch({
                        type: AUTH_ERROR,
                        payload: false,
                        auth: null,
                        user: null,
                        authError: 'Invalid token'
                
                    });
                    localStorage.setItem('goToLogout', true);
                    navigation("/logout");
                }
            })
    } 
    catch(err) {
        console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
            dispatch({
                type: AUTH_ERROR,
                auth: null,
                logoutType: 'timed-out',
                logoutMsg: 'Your session has timed out'
            });
            navigation("/logout");
        } else {
            dispatch({
                type: AUTH_ERROR,
                errorMessage: err.message,
                errorCode: err.response.status
            })
        }
    }
  }, [navigation, backendUrl])

//Login User
    const loginUser = async (userName, password) => {
            setAuthLoading();
            try {
        const res = await axios.post(`${backendUrl}/api/v1/auth/login`, { 
            userName,
            password,
           credentials: 'include',
    })
    console.log(res.data);
    localStorage.setItem("auth", res.data?.jwt);
        dispatch({
            type: GET_USER,
            payload: res.data.jwt,
            user: res.data.user,
            isAuthenticated: res.data.jwtStatus,
            portalUser: res.data.user
        })
        return res.data;

    } 
        
    catch(err) {
        console.log(err);
        if (err.response.status === 401 || err.response.status === 403) {
            dispatch({
                type: LOGIN_ERROR,
                auth: null,
                logoutType: 'timed-out',
                logoutMsg: 'Your session has timed out'
            });
            <Navigate to="/logout" />
        } else {
            dispatch({
                type: LOGIN_ERROR,
                loginErrorMsg: err.response.data.apiError,
                errorMessage: err.message,
                errorCode: err.response.status
            })
        }
    }
    }
    // const headerLinks = async()  => {
    //     const links = await axios.get();

    //     dispatch({
    //         type: HEADER_LINKS,
    //         payload: links
    //     })
    // }

    const formSubmit = async (body, form, department) => {
        setLoading();
        let submittedForm = null;
        try {
            if (form === 'newHire') {
            submittedForm = await axios.post(`${backendUrl}/api/v1/${department}/${form}Submit`, body, {
                headers: {
                    "Content-Type": "multipart/form-data",
                    "Authorization": 'Bearer ' + state.auth
                }
            })
            } else {
            submittedForm = await axios.post(`${backendUrl}/api/v1/${department}/${form}Submit`, body,
                {              
                    headers: {
                        "Authorization": 'Bearer ' + state.auth
                    }
                })
            }
                console.log("Submitted form result: ", submittedForm)
                if (submittedForm.data.success === true) {
                    dispatch({
                        type: FORM_SUBMISSION,
                        successMessage: 'Your form has been submitted successfully!',
                        submitStatus: true,
                        submitSuccess: true,
                        success: true,
                        loading: false,
                    })
                    if (state.success && !state.loading) {
                        console.log(state.success);
                        return redirect('/success');
                    } else {
                        return redirect('/')
                    }
                } else {
                    dispatch({
                        type: ERROR_STATES,
                        payload: submittedForm.data,
                        submitFailed: true,
                        submitStatus: false,
                        errorMessage: submittedForm.data.apiError.errorMessage,
                        errorCode: submittedForm.status,
                        success: submittedForm.data.success,
                        loading: false,
                    })
            }
        } catch(err) {
            console.log(err);
            console.log(err.config.data);
            if (err.request.status === 401 || err.request.status === 403) {
                dispatch({
                    type: GET_LOGOUT,
                    auth: null,
                    logoutType: 'timed-out',
                    logoutMsg: 'Your session has timed out'
                });
                <Navigate to="/logout" />
            }
            dispatch({
                type: ERROR_STATES,
                errorMessage: err.response.data.apiError ? err.response.data.apiError : err.response.data.message,
                errorCode: err.response.status,
                submitStatus: true,
                submitFailed: true,
                submittedData: err.config.data,
                success: false,
                loading: false,
            })
        }
    }
    const setLoading = () => {
        dispatch({ type: SET_LOADING})
    }

    const setAuthLoading = () => {
        dispatch({ type: SET_AUTH_LOADING})
    }
    const setStoreLoading = () => {
        dispatch({ type: SET_STORE_LOADING})
    }
    const resetErrorCodeMsg = () => {
        dispatch({
            type: ERROR_STATES,
            errorCode: false,
            errorMessage: false,
            submitStatus: false,
            submitFailed: false
        })
    }
    const setSubmitFailed = (valid) => {
        console.log('In submit failed')
        dispatch({ 
            type: SET_SUBMIT_FAILED,
            submitFailed: valid,
        })
    }

    const resetSubmissionStates = () => {
        dispatch({
            type: RESET_FORM_SUBMISSION_STATES,
            submitSuccess: false,
            submitFailed: false,
            submitStatus: false,
        })
    }

return <CbmContext.Provider
    value={{
        user: state.user,
        stores: state.stores,
        stateList: state.stateList,
        errorMessage: state.errorMessage,
        errorCode: state.errorCode,
        loginErrorMsg: state.loginErrorMsg,
        submitStatus: state.submitStatus,
        submitFailed: state.submitFailed,
        submitSuccess: state.submitSuccess,
        submittedData: state.submittedData,
        isAuthenticated: state.isAuthenticated,
        loading: state.loading,
        storeLoading: state.storeLoading,
        authLoading: state.authLoading,
        auth: state.auth,
        retrieveStores: state.retrieveStores,
        success: state.success,
        successMessage: state.successMessage,
        psListRetrieved: state.psListRetrieved,
        stateListRetrieved: state.stateListRetrieved,
        headerLinks: state.headerLinks,
        termType: state.termType,
        psList: state.psList,
        portalUser: state.portalUser,
        targetOrderItems: state.targetOrderItems,
        logoutMsg: state.logoutMsg,
        logoutType: state.logoutType,
        loginUser,
        logout,
        getStores,
        getStates,
        getPS,
        clearErrors,
        getTargetItems,
        validateToken,
        formSubmit,
        clearSuccess,
        setTermType,
        resetSubmissionStates,
        setSubmitFailed,
        resetErrorCodeMsg,
    }}>
        {props.children}
</CbmContext.Provider>
}
export default CbmState;