import React, { Fragment, useContext, useEffect } from 'react'
import './Backpay.css';
import Store from '../Store/Store';
import Comment from '../Comment/Comment';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import { useState } from 'react';
import CbmContext from '../../Context/Cbm/cbmContext';
import { validateData, updateErrorFields, removeValidErr, updateEmptyErrorFields } from '../Utils/helperFuncs';

const Backpay = ({ setPayLoad, setFormError }) => {

  const cbmContext = useContext(CbmContext);
  const { stores, portalUser, submittedData, errorCode } = cbmContext;
  const [fieldErrors, setFieldErrors] = useState([]);
  const [backPays, setBackPays] = useState(
    [
      {
        date: '',
        punchIn: '',
        left_lunch: null,
        return_lunch: null,
        punchOut: ''
      }
    ]
  )
  const [backPayData, setBackPayData] = useState({
    employee: {
      employeeNumber: '',
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
    },
    backPayDates: backPays,
    payMissed: '',
    store: null,
    sig: null,
    portalUser
  })

  useEffect(() => {
    if (fieldErrors.length > 0) {
      console.log('More than 0')
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      console.log('0 items')
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])
  
  useEffect(() => {
    setPayLoad(backPayData);
  }, [setPayLoad, backPayData])

  const updateBackPays = (e, index) => {
    const { name, value } = e.target;
    const backPayTemp = [...backPays];
    backPayTemp[index][name] = value;
    setBackPays(backPayTemp);
    setBackPayData({...backPayData, backPayDates: backPayTemp})
  }

  const addBackPay = () => {
    const backPayTemp = [...backPays];
    backPayTemp.push({date: '', punchIn: '', left_lunch: null, return_lunch: null, punchOut: ''})
    setBackPays(backPayTemp);
    setBackPayData({...backPayData, backPayDates: backPayTemp})
  }

  const removeBackPay = (index) => {
    const backPayTemp = [...backPays]
    const newBackPayList = backPayTemp.filter((entry, i) => { return i !== index })
    setBackPays(newBackPayList);
    setBackPayData({...backPayData, backPayDates: newBackPayList})

  }

  const checkDataValidation = (name, value, currentData, currentErrorList) => {
    let newErrorList = [];
    if (( value === '' || value === null)) {
      newErrorList = updateEmptyErrorFields(name, currentErrorList);
      console.log(newErrorList);
      setFieldErrors(newErrorList)
      
    } else if (validateData(name, value, currentData) === false) {
      if (fieldErrors?.length > 0) {
        newErrorList = updateErrorFields(name, currentErrorList);
        console.log(newErrorList);
        setFieldErrors(newErrorList)
      } else {
        newErrorList = updateErrorFields(name, []);
        console.log(newErrorList);
        setFieldErrors(newErrorList)
      }
    } else if (validateData(name, value, currentData)){
      setFieldErrors(removeValidErr(name, currentErrorList));
    }
  }

  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  const changeEmpInfo = (e) => {
    const { name, value } = e.target;
    let tempBackPayData = {...backPayData};
    // checkDataValidation(name, value, tempBackPayData, [...fieldErrors])
    tempBackPayData.employee[name] = value;
    setBackPayData(tempBackPayData);

  }

  const changeStoreInfo = (e) => {
    const {value} = e.target;
    const foundStore = stores.filter(store => {
      return parseInt(value) === store.id;
    })
    setBackPayData({...backPayData, store: foundStore[0]});
  }

  const setPayMissed = (e) => {
    const {value} = e.target;
    setBackPayData({...backPayData, payMissed: value});
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      console.log(JSON.parse(submittedData))
      setBackPayData(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setBackPayData, errorCode])
  return (
    <Fragment>
      <div className='heading'>
        <h1>Backpay</h1>
      </div>
      <fieldset>
        <legend>Employee Info</legend>
        <EmployeeInfo payload={backPayData} checkInputValid={checkInputValid} changeEmpInfo={changeEmpInfo}/>
      </fieldset>
      <fieldset className='backPayDates'>
        <legend>Backpay Dates</legend>
        { backPays.map((backPay, index) => {
          return (
          <div key={'backPay_' + index} className='backPayDate'>
            <label htmlFor='date' className='dateLbl required'>Date 1</label>
            <input type='date' id='date' name={'date'} onChange={(e) => updateBackPays(e, index)} value={backPay.date} className='dateItem'/>
            <label htmlFor='in' className='dateLbl required'>in 1</label>
            <input type='datetime-local' id='in' name={'punchIn'} onChange={(e) => updateBackPays(e, index)} value={backPay.in} className='dateItem'/>
            <label htmlFor='left_lunch' className='dateLbl required'>left_lunch 1</label>
            <input type='datetime-local' id='left_lunch' name={'left_lunch'} onChange={(e) => updateBackPays(e, index)} value={backPay.left_lunch} className='dateItem'/>
            <label htmlFor='return_lunch' className='dateLbl required'>return_lunch 1</label>
            <input type='datetime-local' id="return_lunch" name={'return_lunch'} onChange={(e) => updateBackPays(e, index)} value={backPay.return_lunch} className='dateItem'/>
            <label htmlFor='out' className='dateLbl required'>out 1</label>
            <input type='datetime-local' id="out" name={'punchOut'} onChange={(e) => updateBackPays(e, index)} value={backPay.out} className='dateItem' />
            <div className='backPayBtns'><button onClick={() => addBackPay()}>Add entry</button>{backPays.length > 1 && <button onClick={() => removeBackPay(index)}>Remove entry</button>}</div>
          </div>
          )
          }) 
        }
      </fieldset>
      <fieldset className='backPayInfo'>
        <legend>Backpay Info</legend>
        <Store storeId='payMissedLocationId' storeValue={backPayData.store?.id} changeStoreInfo={changeStoreInfo} header='Location' storeList={stores} />
        <Comment commentsId={'payMissedId'} payload={backPayData.payMissed} onHandleChange={setPayMissed} />
      </fieldset>
    </Fragment>
  )
}
// eslint-disable-next-line
Backpay.propTypes = {}

export default Backpay
