import React, { Fragment, useContext } from 'react'
import Store from '../Store/Store';
import Comment from '../Comment/Comment';
import './TargetOrder.css';
import CbmContext from '../../Context/Cbm/cbmContext';
import { useEffect } from 'react';
import { useState } from 'react';

const TargetOrder = ({ setPayLoad }) => {
  
  const cbmContext = useContext(CbmContext);
  const { stores, getTargetItems, targetOrderItems, portalUser, submittedData, errorCode } = cbmContext;
  const [targetOrderData, setTargetOrderData] = useState({
    store: {
      district: {}
    },
    targetOrderItems: [],
    comments: '',
    portalUser
  })

  useEffect(() => {
    setPayLoad(targetOrderData);
  }, [setPayLoad, targetOrderData])
  
  useEffect(() => {
    if (targetOrderItems.length === 0) {
      getTargetItems();
    }
  }, [getTargetItems, targetOrderItems.length])

  const handleTargetItems = (e, itemName, itemType) => {
    const { value } = e.target;
    let tempOrder = {...targetOrderData};
    let duplicateItem = tempOrder.targetOrderItems.filter(item => item.name === itemName);
    if (value === '') {
      let deleteItem = tempOrder.targetOrderItems.filter((item) =>  item.name === itemName )
      tempOrder.targetOrderItems.splice(tempOrder.targetOrderItems.indexOf(deleteItem[0]), 1);
      setTargetOrderData(tempOrder);
    }
    else if (duplicateItem.length === 0) {
      tempOrder.targetOrderItems.push({name: itemName, quantity: value})
      setTargetOrderData(tempOrder);
    } else if (duplicateItem.length > 0 && itemType !== 'nonRadio') {
        if (value === "0") {
          let deleteItem = tempOrder.targetOrderItems.filter((item) =>  item.name === itemName )
          tempOrder.targetOrderItems.splice(tempOrder.targetOrderItems.indexOf(deleteItem[0]), 1);
        } else {
          tempOrder.targetOrderItems[tempOrder.targetOrderItems.indexOf(duplicateItem[0])].quantity = value;
        }
      setTargetOrderData(tempOrder);
    }
    
  }

  const generateItemList = () => {
    let list;
    list = targetOrderItems.map((item, index) => {
      if (item.itemType === 'nonRadio') {
        return (
          <Fragment key={'item_' + index+1}>
            <div className='itemName'>
              <p>{item.itemName}</p>
            </div>
            <div className='itemQuantity'>
              <input type="number" name="nonRadioItem" id={`${item.itemName}_id`} onChange={(e) => handleTargetItems(e, item.itemName, item.itemType, item.id)} />
            </div>
         </Fragment>
        )
      } else {
        return (
          <Fragment key={'item_' + index+1}>
            <div className='itemName'>
              <p>{item.itemName}</p>
            </div>
            <div className='itemQuantity'>
            <label>
              <input type="radio" name={`radioItem_${index}`} id={`${item.itemName}_0_id`} value={0} onChange={(e) => handleTargetItems(e, item.itemName, item.itemType, item.id)} />
              0
            </label>
            <label>
              <input type="radio" name={`radioItem_${index}`} id={`${item.itemName}_1_id`} value={item.itemType === 'radio6' ? 1*6 : 1} onChange={(e) => handleTargetItems(e, item.itemName, item.itemType, item.id)} />
              {item.itemType === 'radio6' ? 1*6 : 1}
            </label>
            <label>
              <input type="radio" name={`radioItem_${index}`} id={`${item.itemName}_2_id`} value={item.itemType === 'radio6' ? 2*6 : 2} onChange={(e) => handleTargetItems(e, item.itemName, item.itemType, item.id)} />
              {item.itemType === 'radio6' ? 2*6 : 2}
            </label>
            </div>
          </Fragment>
        )
      }
    })
    return list;
  }

  const changeStoreInfo = (e) => {
    const {value} = e.target;
    const foundStore = stores.filter(store => {
      return parseInt(value) === store.id;
    })
    setTargetOrderData({...targetOrderData, store: foundStore[0]});
  }

  const handleComments = (e) => {
    const {value} = e.target;
    setTargetOrderData({...targetOrderData, comments: value});
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      console.log(JSON.parse(submittedData))
      setTargetOrderData(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setTargetOrderData, errorCode])
  return (
    <Fragment>
      <div className='heading'>
        <h1>Target Order</h1>
      </div>
      <fieldset>
        <legend>Store Info</legend>
        <Store  changeStoreInfo={changeStoreInfo} storeValue={targetOrderData.store?.id} storeId={'targetOrderLocation'} header='Location' storeList={stores} />
      </fieldset>
      <fieldset className='targetOrderInfo'>
        <legend>Target Order Items</legend>
        <div className='targetItems'>
          {targetOrderItems.length > 0 && generateItemList()}
        </div>
      </fieldset>
      <fieldset className='commentInfo'>
        <legend>Comments</legend>
        <div className='comments'>
          <Comment onHandleChange={handleComments} payload={targetOrderData.comments} />
        </div>
      </fieldset>
    </Fragment>
  )
}
// eslint-disable-next-line
TargetOrder.propTypes = {}

export default TargetOrder
