import React, { useContext } from 'react'
import './Store.css';
import CbmContext from '../../Context/Cbm/cbmContext';

const Store = props => {
  const cbmContext = useContext(CbmContext);
  const { errorCode } = cbmContext;
  return (
    <div className='storeInfo'>
        <label htmlFor={props.storeId} className='storeLbl required'>{props.header}</label>
        <select id={props.storeId} required name={props.elementName} value={props.storeValue} className='store' onChange={(e) => props.changeStoreInfo(e, props.index)}>
          <option value='' className='storeItem'>Select location</option>
            {(errorCode !== 401 || errorCode !== 403) && props.storeList.map(store => {
              return <option key={'store_'+ store.id} value={store.id} className='storeItem'>{store.name}</option>
            })}
        </select>
    </div>
  )
}
// eslint-disable-next-line
Store.propTypes = {}

export default Store