import React, { useContext, useEffect } from 'react'
import './Logout.css';
import CbmContext from '../../Context/Cbm/cbmContext';
import { Link } from 'react-router-dom';

function Logout() {

    const cbmContext = useContext(CbmContext);
    const { logout, logoutMsg } = cbmContext;

    useEffect(() => {
        if (localStorage.getItem('goToLogout')) {
            logout();
        }
        // localStorage.removeItem('goToLogout');
        if (localStorage.getItem('goToLogout')) {
            localStorage.removeItem('goToLogout');
        }
    }, [logout])

    useEffect(() => {
        if (localStorage.getItem('auth')) {
            localStorage.removeItem('auth');
        }
        if (localStorage.getItem('goToLogout')) {
            localStorage.removeItem('goToLogout');
        }
    }, [])
  return (
    <div className='logout-content'>
        <div className='logoutMsg'>
            <p>{logoutMsg !=='' ? logoutMsg : "You've been logged out successfully."}</p>
            <p>Click <a href='/login' >here</a> to go to the login page</p>
        </div>
    </div>
  )
}

export default Logout