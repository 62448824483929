import React, { useContext, useState, useEffect, Fragment } from 'react'
import Store from '../Store/Store'
import './PerDiem.css';
import Comment from '../Comment/Comment';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import CbmContext from '../../Context/Cbm/cbmContext';
import { updateEmptyErrorFields, updateErrorFields, removeValidErr, validateData } from '../Utils/helperFuncs';

const PerDiem = ({ setPayLoad, setFormError }) => {

  const cbmContext = useContext(CbmContext);
  const { stores, user, submittedData, errorCode } = cbmContext;
  const [fieldErrors, setFieldErrors] = useState([]);
  const [perDiemData, setPerDiemData] = useState({
    employee: {
      employeeNumber: '',
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
    },
    firstHotelNight: '',
    lastHotelNight: '',
    arrivalDate: '',
    departureDate: '',
    mileageDates: [{
      date: '',
      location1: null,
      location2: null,
      rt_ow: null
    }],
    store: null,
    reasonPayMissed: '',
    portalUser:user
  })

  const onHandleChange = (e) => {
    const {name, value} = e.target;
    let currentPerDiemData = {...perDiemData};
    currentPerDiemData[name] = value;
    setPerDiemData(currentPerDiemData)
  }

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])
  
  useEffect(() => {
    setPayLoad(perDiemData);
  }, [setPayLoad, perDiemData])

  const addMileageEntry = () => {
    let currentPerDiemData = {...perDiemData}
    let mileageEntryTemplate = { date: '', location1: '', location2: '', rt_ow: ''}
    currentPerDiemData.mileageDates.push(mileageEntryTemplate);
    setPerDiemData(currentPerDiemData);
  }

  const deleteMileageEntry  = (index) => {
    const mileageTemp = [...perDiemData.mileageDates];
    const newMileageList = mileageTemp.filter((mileage, i) => {return i !== index })
    setPerDiemData({...perDiemData, mileageDates: newMileageList})
  }

  const updateMileages = (e, index) => {
    const { name, value, id } = e.target;
    let mileageTemp = [...perDiemData.mileageDates];
    let foundStore = {};
      if (name === 'store' || name === 'location1' || name === 'location2') {
        foundStore = filterStore(parseInt(value))
        mileageTemp[index][name] = foundStore;
    } else if (id === 'rt' || id === 'ow') {
        let radioOption = name.split('_')
        radioOption = radioOption[0] + '_' + radioOption[1];
        mileageTemp[index][radioOption] = value;
    } else {
      mileageTemp[index][name] = value;
    }
    setPerDiemData({...perDiemData, mileageDates: mileageTemp})
  }

  const changeStoreInfo = (e) => {
    const {value} = e.target;
    const foundStore = filterStore(parseInt(value))
    setPerDiemData({...perDiemData, store: foundStore});
  }

  const filterStore = (value) => {
    let foundStore = [];
    foundStore = stores.filter(store => {
      if (value === store.id) {
        return store;
      } else {
        return null;
      }
    })
    return foundStore[0];
  }

  const checkDataValidation = (name, value, currentData, currentErrorList) => {
    let newErrorList = [];
    if (( value === '' || value === null)) {
      newErrorList = updateEmptyErrorFields(name, currentErrorList);
      setFieldErrors(newErrorList)
      
    } else if (validateData(name, value, currentData) === false) {
      if (fieldErrors?.length > 0) {
        newErrorList = updateErrorFields(name, currentErrorList);
        setFieldErrors(newErrorList)
      } else {
        newErrorList = updateErrorFields(name, []);
        setFieldErrors(newErrorList)
      }
    } else if (validateData(name, value, currentData)){
      setFieldErrors(removeValidErr(name, currentErrorList));
    }
  }

  
  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  const changeEmpInfo = (e) => {
    const { name, value } = e.target;
    let tempPerDiem = {...perDiemData};
    // checkDataValidation(name, value, tempPerDiem, [...fieldErrors])
    tempPerDiem.employee[name] = value;
    setPerDiemData(tempPerDiem);
  }

  const handleComments = (e) => {
    const {value} = e.target;
    setPerDiemData({...perDiemData, reasonPayMissed: value});
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      setPerDiemData(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setPerDiemData, errorCode])
  return (
    <Fragment>
    <div className='heading'>
      <h1>PerDiem</h1>
    </div>
    <fieldset>
      <legend>Employee Info</legend>
      <EmployeeInfo payload={perDiemData} checkInputValid={checkInputValid} changeEmpInfo={changeEmpInfo} />
    </fieldset>
    <fieldset className='location-info'>
      <legend>Store Info</legend>
      <Store storeList={stores} header='Store:' stoerValue={perDiemData.store?.id} elementName='store' changeStoreInfo={changeStoreInfo} />
    </fieldset>
    <fieldset className='perDiemHotel'>
      <legend>Hotel Info</legend>
      <div className='perDiemHotelInfo'>
        <div className='firstHotelNight'>
          <label className='required'>First hotel night needed: </label>
          <input type='date' name="firstHotelNight" onChange={(e) => onHandleChange(e)}/>
        </div>
        <div className='lastHotelNight'>
          <label className='required'>Last hotel night needed: </label>
          <input type='date'  name="lastHotelNight" onChange={(e) => onHandleChange(e)}/>
        </div>
        <div className='arrivalDate'>
          <label className='required'>Arrival date: </label>
          <input type='date'  name="arrivalDate" onChange={(e) => onHandleChange(e)}/>
        </div>
        <div className='departureDate'>
        <label className='required'>Departure date: </label>
        <input type='date'  name="departureDate" onChange={(e) => onHandleChange(e)}/>
        </div>
      </div>
    </fieldset>
    <fieldset className='mileageDates'>
          <legend>Mileage date</legend>
          {perDiemData.mileageDates.map((mileageDate, i) => {
            return (
            <Fragment key={'perDiem_' + i + 1}>
              <label>{`Mileage ${i}`}</label>
              <div id="mileageDate" className='perDiem-mileage-date-container'>
                <div className='mileageDate'>
                  <label className='required'>Date</label>
                  <input type="date" name='date' onChange={(e) => updateMileages(e, i)} value={mileageDate.date} />
                </div>
                <div className='location1'>
                  <Store storeList={stores} header='Start location:' storeValue={perDiemData.mileageDates[i].location1?.id} elementName='location1' index={i} changeStoreInfo={updateMileages} />
                </div>
                <div className='location2'>
                  <Store storeList={stores} header='End location:' storeValue={perDiemData.mileageDates[i].location2?.id} elementName='location2' index={i} changeStoreInfo={updateMileages} />
                </div>
              </div>
              <div className='rt-ow'>
                <label className='required'>Select RT/OW:</label> 
                <div>
                  Round trip 
                  <input type="radio" checked={mileageDate.rt_ow === 'RT'}  id='rt' name={'rt_ow_' + i} value='RT' onChange={(e) => updateMileages(e, i)} /> 
                </div>
                <div>
                  One way 
                  <input type='radio' checked={mileageDate.rt_ow === 'OW'} id='ow' name={'rt_ow_' + i} value='OW' onChange={(e) => updateMileages(e, i)}/>
                </div>
              </div>
              <div className='perDiem-add-remove-btns'>
                <div className='add'>
                    <button onClick={addMileageEntry}>Add mileage</button>
                </div>
                { perDiemData.mileageDates.length > 1 &&
                <div className='remove'>
                    <button onClick={(e) => deleteMileageEntry(i)}> Remove mileage</button>
                </div>
                }
              </div>
            </Fragment>
            )
          })}
        </fieldset>
        <fieldset className='reasonMissedWrapper'>
          <legend>Comments</legend>
          <Comment onHandleChange={handleComments} />
        </fieldset>
    </Fragment>
  )
}
// eslint-disable-next-line
PerDiem.propTypes = {}

export default PerDiem