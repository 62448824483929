import './Login.css';
import React, { Fragment, useContext, useState } from 'react'
import CbmContext from '../../Context/Cbm/cbmContext';
import { useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation, faSpinner } from '@fortawesome/free-solid-svg-icons';

function Login(props) {

    const cbmContext = useContext(CbmContext);
    const {loginUser, loginErrorMsg, errorCode, authLoading } = cbmContext;
    const navigate = useNavigate();
    const [loginData, setLoginData] = useState({
        userName: '',
        password: ''
    })

    const onHandleChange = (e) => {
        const { name, value } = e.target;
        let tempLoginData = {...loginData};
        tempLoginData[name] = value;
        setLoginData(tempLoginData);
    }

    const onSubmit = async (e) => {
        e.preventDefault();
        if ((loginData.userName !== null || loginData.userName !== '') && (loginData.password !== '' || loginData.password !== null)) {
           let res = await loginUser(loginData.userName, loginData.password);
           if (res?.user?.id || (errorCode?.indexOf(4) > -1 || errorCode?.indexOf(5) > -1)) {
            navigate("/")
           } else {
            navigate("/login")
           }
        }
    } 

  return (
    <Fragment>
        {authLoading ?
        <FontAwesomeIcon className="loading-spinner" icon={faSpinner} spin size="2xl" />
        :
        <Fragment>
        {(loginErrorMsg !== '') && <div className='loginErrMsg'><FontAwesomeIcon icon={faCircleExclamation} /><p>{loginErrorMsg?.errorMessage}</p></div>}
        <form onSubmit={(e) => onSubmit(e)} className='login-form'>
            <div className='container'>
                <div className='loginInputs'>
                    <div className='usernameInput'>
                        <label htmlFor='userName'>Username</label>
                        <input type="text" name="userName" id='userName' autoComplete='true' value={loginData.userName} onChange={(e) => onHandleChange(e)} />
                    </div>
                    <div className='passwordInput'>
                        <label htmlFor='password'>Password</label>
                        <input type="text" name="password" id='password' autoComplete='true' value={loginData.password} onChange={(e) => onHandleChange(e)} />
                    </div>
                </div>
            </div>
            <button type='submit' className='submit-btn'>Login</button>
        </form>
        </Fragment>
    }
    </Fragment>
  )
}

Login.propTypes = {}

export default Login
