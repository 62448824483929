import React, {useState, useEffect, useContext, Fragment} from 'react'
import './Uniform.css';
import CbmContext from '../../Context/Cbm/cbmContext';
import { validateData, updateEmptyErrorFields, updateErrorFields, removeValidErr } from '../Utils/helperFuncs';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';

const Uniform = ({ setPayLoad, setFormError }) => {

  const cbmContext = useContext(CbmContext);
  const { portalUser, submittedData, errorCode, getStates, stateList, stateListRetrieved } = cbmContext;
  const [uniformInfo, setUniformInfo] = useState({
    employee: {
      employeeNumber: 0,
      employeeFirst: '',
      employeeLast: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      },
    },
    total: 0,
    quantity: 0,
    size: '',
    sig: null,
    portalUser
  });

  const [fieldErrors, setFieldErrors] = useState ([]);

  useEffect(() => {
    return () => {
      if (stateListRetrieved === false) {
        getStates();
      }
    }
  }, [getStates, stateListRetrieved])
  
  useEffect(() => {
    setPayLoad(uniformInfo);
  }, [setPayLoad, uniformInfo])

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])

  const handleAddressInfo = (e) => {
    const {name, value} = e.target;
    let currentAddressInfo = {...uniformInfo};
    let currentErrorList = '';
    if (( value === '' || value === null)) {
      setFieldErrors(updateEmptyErrorFields(name, fieldErrors));
    } else if (validateData(name, value, currentAddressInfo) === false) {
      if (fieldErrors?.length > 0) {
        currentErrorList = updateErrorFields(name, fieldErrors);
        setFieldErrors(currentErrorList)
        setFormError(true);
      } else {
        currentErrorList = updateErrorFields(name, []);
        setFieldErrors(currentErrorList)
      }
    } else {
      currentErrorList = removeValidErr(name, fieldErrors);
      setFieldErrors(currentErrorList);
    }
    if (fieldErrors?.length > 0) {
      setFormError(true);
    } else {
      setFormError(false);
    }
    currentAddressInfo.employee.address[name] = value;
    setUniformInfo(currentAddressInfo);
  }

  const calculateTotalCost = (numShirts) => {
    let currentUniformInfo = {...uniformInfo}
    currentUniformInfo['total'] = numShirts * 7;
    currentUniformInfo['quantity'] = parseInt(numShirts);
    return currentUniformInfo;
  }

  const onHandleChange = (e) => {
    const { name, value } = e.target;
      let currentUniformInfo = {...uniformInfo};
      if (name === 'quantity') {
        currentUniformInfo = calculateTotalCost(value)
      } else if (name === "employeeNumber" || name === "employeeFirst" || name === "employeeLast") {
        currentUniformInfo.employee[name] = value;
      } else {
        currentUniformInfo[name] = value;
      }
      let newErrorList = [];
      if (( value === '' || value === null)) {
        newErrorList = updateEmptyErrorFields(name, fieldErrors);
        setFieldErrors(newErrorList)
        
      } else if (validateData(name, value, uniformInfo) === false) {
        if (fieldErrors?.length > 0) {
          newErrorList = updateErrorFields(name, fieldErrors);
          setFieldErrors(newErrorList)
        } else {
          newErrorList = updateErrorFields(name, []);
          setFieldErrors(newErrorList)
        }
      } else if (validateData(name, value, uniformInfo)){
        setFieldErrors(removeValidErr(name, fieldErrors));
      }
      setUniformInfo(currentUniformInfo);
  }

  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      setUniformInfo(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setUniformInfo, errorCode])
  return (
    <Fragment>
      <div className='heading'>
        <h1>Uniform</h1>
      </div>
      <fieldset className='uniformEmployeeInfo'>
        <legend>Employee Information</legend>
          <EmployeeInfo payload={uniformInfo} checkInputValid={checkInputValid} changeEmpInfo={onHandleChange} />
          <div className='addressInfo'>
            <div className='address1'>
              <label htmlFor="address1" className='required'>Address:</label>
              <input type="text" id="address1" name='address1' value={uniformInfo.employee.address.address1} className={checkInputValid('address1') ? 'address1': 'address1 input-invalid'} onChange={(e) => handleAddressInfo(e)} />
            </div>
            <div className='address2'>
              <label htmlFor="address2" className='required'>Apt #:</label>
              <input type="text" id="address2" name='address2' value={uniformInfo.employee.address.address2} className={checkInputValid('address2') ? 'address2': 'address2 input-invalid'} onChange={(e) => handleAddressInfo(e)} />
            </div>
            <div className='city'>
              <label htmlFor="city" className='required'>City:</label>
              <input type="text" id="city" name='city' value={uniformInfo.employee.address.city} className={checkInputValid('city') ? 'city': 'city input-invalid'} onChange={(e) => handleAddressInfo(e)} />
            </div>
            <div className='state'>
              <label htmlFor="state" className='required'>State:</label>
              <select id="state" name='state' className='state' value={uniformInfo.employee.address.state} onChange={(e) => handleAddressInfo(e)} >
                <option>Select state</option>  
                {stateList?.map((state, index) => {
                  return <option value={state.stateShortName} key={index}>{state.stateName}</option>
                })}
              </select>
            </div>
            <div className='zip'>
              <label htmlFor="zip" className='required'>Zip:</label>
              <input type="text" id="zip" name='zip'  value={uniformInfo.employee.address.zip} className={checkInputValid('zip') ? 'zip': 'zip input-invalid'} onChange={(e) => handleAddressInfo(e)} />
            </div>
          </div>
      </fieldset>
      <fieldset>
        <legend>Uniform Info</legend>
        <div className='uniformInfo'>
          <div className='shirtQuantity'>
            <label htmlFor='quantity'>Enter shirt quantity:</label>
            <input type="number" name='quantity' value={uniformInfo.quantity} id='quantity' onChange={(e) => onHandleChange(e)} />
          </div>
          <div className='uniformSize'>
            <label>Uniform size:</label>
            <div className='sizes'>
              <div className='sizeSmall'>
                <label htmlFor='small'>S</label><input type='radio' checked={uniformInfo.size === 'Small'} name="size" id='small' value='Small' onChange={(e) => onHandleChange(e)} />
              </div>
              <div className='sizeMedium'>
                <label htmlFor='medium'>M</label><input type='radio' checked={uniformInfo.size === 'Medium'} name="size" id='medium' value='Medium' onChange={(e) => onHandleChange(e)} />
              </div>
              <div className='sizeLarge'>
                <label htmlFor='large'>L</label><input type='radio' checked={uniformInfo.size === 'Large'} name="size" id='large' value='Large' onChange={(e) => onHandleChange(e)} />
              </div>
              <div className='sizeXLarge'>
                <label htmlFor='x-large'>XL</label><input type='radio' checked={uniformInfo.size === 'XLarge'} name="size" id='x-large' value='XLarge' onChange={(e) => onHandleChange(e)} />
              </div>
            </div>
          </div>
        </div>
      </fieldset>
    </Fragment>
  )
}
// eslint-disable-next-line
Uniform.propTypes = {}

export default Uniform
