import React, {useState, useEffect, useContext, Fragment} from 'react'
import './TimeAdjustment.css';
import Comment from '../Comment/Comment';
import CbmContext from '../../Context/Cbm/cbmContext';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import { updateEmptyErrorFields, updateErrorFields, removeValidErr, validateData } from '../Utils/helperFuncs';

const TimeAdjustment = ({ setPayLoad, setFormError }) => {

  const cbmContext = useContext(CbmContext);
  const { portalUser, submittedData, errorCode } = cbmContext;
  const [fieldErrors, setFieldErrors] = useState([]);
  const [timeAdjustmentData, setTimeAdjustmentData] = useState({
    employee: {
      employeeNumber: '',
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
    },
    breaks: [
      {
        date: '',
        punchIn: '',
        punchOut: '',
        breakAmount: ''
      }
    ],
    reasonMissed: '',
    managerSig: null,
    employeeSig: null,
    portalUser
  })

  useEffect(() => {
    setPayLoad(timeAdjustmentData);
  }, [setPayLoad, timeAdjustmentData])

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])

  const updateAdjustment = (e, index) => {
    const { name, value } = e.target;
    const adjustmentTemp = [...timeAdjustmentData.breaks];
    adjustmentTemp[index][name] = value;
    setTimeAdjustmentData({...timeAdjustmentData, breaks: adjustmentTemp})
  }

  const addAdjustment = () => {
    const adjustmentTemp = [...timeAdjustmentData.breaks];
    adjustmentTemp.push({date: '', punchIn: '', punchOut: '', breakAmount: ''})
    setTimeAdjustmentData({...timeAdjustmentData, breaks: adjustmentTemp})
  }

  const removeAdjustment = (index) => {
    const adjustmentTemp = [...timeAdjustmentData.breaks]
    const newAdjustmentList = adjustmentTemp.filter((entry, i) => { return i !== index })
    setTimeAdjustmentData({...timeAdjustmentData, breaks: newAdjustmentList})

  }

  const checkDataValidation = (name, value, currentData, currentErrorList) => {
    let newErrorList = [];
    if (( value === '' || value === null)) {
      newErrorList = updateEmptyErrorFields(name, currentErrorList);
      setFieldErrors(newErrorList)
      
    } else if (validateData(name, value, currentData) === false) {
      if (fieldErrors?.length > 0) {
        newErrorList = updateErrorFields(name, currentErrorList);
        setFieldErrors(newErrorList)
      } else {
        newErrorList = updateErrorFields(name, []);
        setFieldErrors(newErrorList)
      }
    } else if (validateData(name, value, currentData)){
      setFieldErrors(removeValidErr(name, currentErrorList));
    }
  }

  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  const changeEmpInfo = (e) => {
    const { name, value } = e.target;
  let timeAdjustTempData = {...timeAdjustmentData}
  // checkDataValidation(name, value, timeAdjustTempData, [...fieldErrors])
  timeAdjustTempData.employee[name] = value;
  setTimeAdjustmentData(timeAdjustTempData);

  }

  const setReasonMissed = (e) => {
    const {value} = e.target;
    setTimeAdjustmentData({...timeAdjustmentData, reasonMissed: value});
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      setTimeAdjustmentData(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setTimeAdjustmentData, errorCode])
  return (
    <Fragment>
      <div className='heading'>
        <h1>Time Adjustment</h1>
      </div>
      <fieldset>
        <legend>Employee Info</legend>
        <EmployeeInfo payload={timeAdjustmentData} checkInputValid={checkInputValid} changeEmpInfo={changeEmpInfo} />
      </fieldset>
      <fieldset className='timeAdjustmentInfo'>
        <legend>Time adjustment info</legend>
        { timeAdjustmentData.breaks.map((adjustment, index) => {
          return (
          <div key={'break' + index} className='timeAdjustment'>
            <div className='adjustmentItem'>
              <label htmlFor='date' className='dateLbl required'>{'Date ' + (index+1)}</label>
              <input type='date' id='date' name={'date'} onChange={(e) => updateAdjustment(e, index)} value={adjustment.date} className='dateItem'/>
            </div>
            <div className='adjustmentItem'>
              <label htmlFor='in' className='dateLbl required'>{'In ' + (index+1)}</label>
              <input type='datetime-local' id='in' name={'punchIn'} onChange={(e) => updateAdjustment(e, index)} value={adjustment.in} className='dateItem'/>
            </div>
            <div className='adjustmentItem'>
              <label htmlFor='out' className='dateLbl required'>{'Out ' + (index+1)}</label>
              <input type='datetime-local' id="out" name={'punchOut'} onChange={(e) => updateAdjustment(e, index)} value={adjustment.out} className='dateItem' />
            </div>
            <div className='adjustmentItem'>
              <label htmlFor='breakTime' className='breakTimeLbl required'>{'Break ' + (index+1)}</label>
              <input type="number" id='breakTime' name='breakAmount' onChange={(e) => updateAdjustment(e, index)} value={adjustment.breakAmount} className='dateItem' />
            </div>
            <div className='timeAdjust-add-remove-btns'><button className='add' onClick={() => addAdjustment()}>Add entry</button>{timeAdjustmentData.breaks.length > 1 && <button className='remove' onClick={() => removeAdjustment(index)}>Remove entry</button>}</div>
          </div>
          )
          }) 
        }
      </fieldset>
      <fieldset className='commentInfo'>
        <legend>Comments</legend>
        <div className='comments'>
        <Comment commentsId={'reasonPayMissedId'} payload={timeAdjustmentData.reasonMissed} onHandleChange={setReasonMissed} />
        </div>
      </fieldset>
    </Fragment>
  )
}
// eslint-disable-next-line
TimeAdjustment.propTypes = {}

export default TimeAdjustment
