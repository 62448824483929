import React, {Fragment, useState, useEffect, useContext} from 'react'
import { useSearchParams } from 'react-router-dom'
import NoCallNoShow from '../TermForms/NoCallNoShow';
import Quit from '../TermForms/Quit';
import NoCallNoShowFirst from '../TermForms/NoCallNoShowFirst';
import Other from '../TermForms/Other';
import './Termination.css';
import CbmContext from '../../Context/Cbm/cbmContext';
import { updateEmptyErrorFields, updateErrorFields, removeValidErr, validateData } from '../Utils/helperFuncs';

const Termination = ({ setPayLoad, setFormError }) => {
  const [queryParams, ] = useSearchParams();
  const [termForm, ] = useState(queryParams.get('termForm'))
  const [fieldErrors, setFieldErrors] = useState([])
  const cbmContext = useContext(CbmContext);
  const { portalUser, submittedData, errorCode } = cbmContext
  const [termInfo, setTermInfo] = useState({
    employee: {
      employeeNumber: '',
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
    },
    quitReason: 'N/A',
    lastDayWorked: '',
    eligibleRehire: false,
    twoWeeksGiven: false,
    warningsGiven: false,
    termType: termForm,
    portalUser
  })

  useEffect(() => {
    setPayLoad(termInfo);
  }, [setPayLoad, termInfo])

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])

  const checkDataValidation = (name, value, currentData, currentErrorList) => {
    let newErrorList = [];
    if (( value === '' || value === null)) {
      newErrorList = updateEmptyErrorFields(name, currentErrorList);
      console.log(newErrorList);
      setFieldErrors(newErrorList)
      
    } else if (validateData(name, value, currentData) === false) {
      if (fieldErrors?.length > 0) {
        newErrorList = updateErrorFields(name, currentErrorList);
        console.log(newErrorList);
        setFieldErrors(newErrorList)
      } else {
        newErrorList = updateErrorFields(name, []);
        console.log(newErrorList);
        setFieldErrors(newErrorList)
      }
    } else if (validateData(name, value, currentData)){
      setFieldErrors(removeValidErr(name, currentErrorList));
    }
  }

  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  const changeEmpInfo = (e) => {
    const { name, value } = e.target;
    let tempTermInfo = {...termInfo};
    // checkDataValidation(name, value, tempTermInfo, [...fieldErrors])
    tempTermInfo.employee[name] = value;
    setTermInfo(tempTermInfo);
  }

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    console.log(name, ' ', value)
    setTermInfo({...termInfo, [name]:value});
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      console.log(JSON.parse(submittedData))
      setTermInfo(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setTermInfo, errorCode])
  return (
    <Fragment>
        {queryParams.get('termForm') === 'quit' && 
          <Quit 
          changeEmpInfo={changeEmpInfo} 
          onHandleChange={onHandleChange} 
          termInfo={termInfo}
          checkInputValid={checkInputValid}
          />}
        {queryParams.get('termForm') === 'noCall' && 
          <NoCallNoShow          
          changeEmpInfo={changeEmpInfo} 
          onHandleChange={onHandleChange} 
          termInfo={termInfo} 
          checkInputValid={checkInputValid}
          />}
        {queryParams.get('termForm') === 'noShowFirstDay' && 
          <NoCallNoShowFirst          
          changeEmpInfo={changeEmpInfo} 
          onHandleChange={onHandleChange} 
          termInfo={termInfo}
          checkInputValid={checkInputValid}
          />}
        {queryParams.get('termForm') === 'Other' && 
          <Other
          changeEmpInfo={changeEmpInfo} 
          onHandleChange={onHandleChange} 
          termInfo={termInfo}
          checkInputValid={checkInputValid}
          />}
    </Fragment>
  )
}
// eslint-disable-next-line
Termination.propTypes = {}

export default Termination
