import Backpay from "../Backpay/Backpay"
import Bonus from "../Bonus/Bonus"
import Home from "../../Pages/Home"
import Hotel from "../Hotel/Hotel"
import Mileage from "../Mileage/Mileage"
import NonMnMileage from '../NonMnMileage/NonMnMileage'
import NewHire from "../NewHire/NewHire"
import PerDiem from "../PerDiem/PerDiem"
import Pto from "../Pto/Pto"
import TargetOrder from "../TargetOrder/TargetOrder"
import Termination from "../Termination/Termination"
import TimeAdjustment from "../TimeAdjustment/TimeAdjustment"
import Uniform from "../Uniform/Uniform"
import WorkTicket from "../WorkTicket/WorkTicket"

export const renderComponentByFormName = (formName, payload, setPayLoad, submitSuccess, submitFailed, submitStatus, setIdBadgeFile, setGovIdFront, setGovIdRear, setSsnCard, formError, setFormError) => {
    switch(formName) {
        case "backPay": 
            return <Backpay setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "bonus": 
            return <Bonus setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "hotel": 
            return <Hotel setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "mileage": 
            return <Mileage setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />
        
        case "nonMnMileage": 
            return <NonMnMileage setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "newHire": 
            return <NewHire setPayLoad={setPayLoad} setIdBadgeFile={setIdBadgeFile} setGovIdFrontFile={setGovIdFront} setGovIdRearFile={setGovIdRear} setSsnCardFile={setSsnCard} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "perDiem": 
            return <PerDiem setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "pto": 
            return <Pto setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "targetOrder": 
            return <TargetOrder setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "termination": 
            return <Termination setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "timeAdjustment": 
            return <TimeAdjustment setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "uniform": 
            return <Uniform setPayLoad={setPayLoad} payload={payload} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        case "workTicket": 
            return <WorkTicket payload={payload} setPayLoad={setPayLoad} submitSuccess={submitSuccess} submitFailed={submitFailed} submitStatus={submitStatus} formError={formError} setFormError={setFormError} />

        default:
            return <Home />
    }
}

export const formDepartment = (formName) => {

    switch(formName) {
        case 'workTicket':
        case 'perDiem':
        case 'hotel':
        case 'nonMnMileage':
            return 'finance'
        
        case 'uniform':
        case 'termination':
        case 'newHire':
            return 'hr'
        
        case 'timeAdjustment':
        case 'pto':
        case 'mileage':
        case 'bonus':
        case 'backPay':
            return 'payroll'
        
        case 'targetOrder':
            return 'ops'
        default:
            return null
    }

}

export const showManagerSigPad = (formName) => {
    let formsWithSigPad = ['backPay', 'bonus', 'pto', 'targetOrder', 'timeAdjustment', 'uniform'];
    return formsWithSigPad.includes(formName);
}

export const validateData = (fieldName, fieldValue, data) => {
    if (fieldName !== 'employeeFirst' &&
        fieldName !== 'employeeMiddle' &&
        fieldName !== 'employeeLast' && 
        fieldName !== 'employeeSecondLast') {
    
    } else {
        if (Object.keys(data.employee).indexOf(fieldName) > -1 && 
            fieldName !== 'employeeNumber' && fieldName !== 'address2' && fieldName !== 'employeeMName' && fieldName !== 'employeeSLName' && fieldName !== 'quantity') {
                return fieldValue.match(/[A-z,a-z,.]/g) !== null;
            } else if(Object.keys(data.employee.address).indexOf(fieldName) > -1 && fieldName !== 'employeeNumber' && fieldName !== 'quantity') {
                return fieldValue.match(/[A-za-z0-9. ]/) !== null;
            } else if (Object.keys(data).indexOf(fieldName) > -1 && fieldName!== 'employeeNumber' && fieldName !== 'quantity' && fieldName !== 'size') {
                return fieldValue.match(/[A-z,a-z,.]/g) !== null;
            } else {
                return true;
            }
    }
}

export const isEmployeeInfo = (fieldName) => {
    return (fieldName === "employeeNumber" ||
        fieldName === "employeeFirst" ||
        fieldName === "employeeLast" ||
        fieldName === 'city' ||
        fieldName === 'address1'
    )
}

export const updateEmptyErrorFields = (name, data) => {
    let currentErrorList = data?.length > 0 ? data : [];
    let errorIndex = currentErrorList.findIndex(x => x.input === name);
    if (errorIndex === -1 && name !== 'quantity') {
        currentErrorList.push({ input: name, msg: 'Please enter a value'})
        return currentErrorList;
    } else {
        return currentErrorList;
    }
  }
  
export const updateErrorFields = (name, data) => {
    let currentErrorList = data?.length > 0 ? data : [];
    if (currentErrorList?.length > 0) {
        currentErrorList = [...data];
        let errorIndex = currentErrorList.findIndex(x => x.input === name);
        if (errorIndex === -1) {
        currentErrorList.push({ input: name, msg: 'Please enter a valid input'})
        return currentErrorList;
    } else {
        return currentErrorList;
    }
    } else {
        currentErrorList.push({ input: name, msg: 'Please enter a valid input'})
        return currentErrorList
    }
  }

  export const removeValidErr = (name, data) => {
    let currentErrorList = [...data];
    let errorIndex = currentErrorList.findIndex(x => x.input === name);
    if (errorIndex > -1) {
      currentErrorList.splice(errorIndex, 1)
      return currentErrorList;
    }
    return currentErrorList;
  }