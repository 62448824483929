import React, { Fragment } from 'react'
import './Bonus.css';
import Store from '../Store/Store';
import Comment from '../Comment/Comment';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import { useContext } from 'react';
import CbmContext from '../../Context/Cbm/cbmContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { updateEmptyErrorFields, updateErrorFields, removeValidErr, validateData } from '../Utils/helperFuncs';

const Bonus = ({ setPayLoad, setFormError }) => {
  const cbmContext = useContext(CbmContext);
  const { stores, portalUser, submittedData, errorCode } = cbmContext;
  const [fieldErrors, setFieldErrors] = useState([]);
  const [bonusData, setBonusData] = useState({
    employee: {
      employeeNumber: '',
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
    },
    bonuses: [
      {
        date: '',
        store: {},
        bonus: ''
      }
    ],
    comments: '',
    sig: null,
    portalUser,
  })

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])

  useEffect(() => {
    setPayLoad(bonusData);
  }, [setPayLoad, bonusData])

const changeEmpInfo = (e) => {
  const { name, value } = e.target;
    let tempBonusData = {...bonusData}
    // checkDataValidation(name, value, tempBonusData, [...fieldErrors])
    tempBonusData.employee[name] = value;
    setBonusData(tempBonusData);
  }

  const filterStore = (value) => {
    let foundStore = [];
    foundStore = stores.filter(store => {
      if (parseInt(value) === store.id) {
        return store;
      } else {
        return null;
      }
    })
    return foundStore;
  }
  const updateBonuses = (e, index) => {
    const { name, value } = e.target;
    let bonusTemp = [...bonusData.bonuses];
    let foundStore = [];
    console.log(name);
      if (name === 'bonus-store') {
        foundStore = filterStore(value)
        console.log(foundStore)
        bonusTemp[index].store = foundStore[0];
    } else {
      bonusTemp[index][name] = value;
    }
    setBonusData({...bonusData, bonuses: bonusTemp})
  }

  const addBonus = () => {
    const bonusTemp = [...bonusData.bonuses];
    bonusTemp.push(      {
      date: '',
      store: null,
      bonus: ''
    })
    setBonusData({...bonusData, bonuses: bonusTemp})
  }

  const removeBonus = (index) => {
    const bonusTemp = [...bonusData.bonuses]
    const newBonusList = bonusTemp.filter((entry, i) => { return i !== index })
    setBonusData({...bonusData, bonuses: newBonusList})

  }

  const setPayMissed = (e) => {
    const {value} = e.target;
    setBonusData({...bonusData, comments: value})
}

const checkDataValidation = (name, value, currentData, currentErrorList) => {
  let newErrorList = [];
  if (( value === '' || value === null)) {
    newErrorList = updateEmptyErrorFields(name, currentErrorList);
    console.log(newErrorList);
    setFieldErrors(newErrorList)
    
  } else if (validateData(name, value, currentData) === false) {
    if (fieldErrors?.length > 0) {
      newErrorList = updateErrorFields(name, currentErrorList);
      console.log(newErrorList);
      setFieldErrors(newErrorList)
    } else {
      newErrorList = updateErrorFields(name, []);
      console.log(newErrorList);
      setFieldErrors(newErrorList)
    }
  } else if (validateData(name, value, currentData)){
    setFieldErrors(removeValidErr(name, currentErrorList));
  }
}

const checkInputValid = (fieldName) => {
  if (fieldErrors?.length > 0) {
    return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
  } else {
    return true;
  }
}

useEffect(() => {
  if (submittedData !== null && errorCode !== '') {
    console.log(JSON.parse(submittedData))
    setBonusData(JSON.parse(submittedData));
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
  } 
}, [submittedData, setBonusData, errorCode])

  return (
    <Fragment>
      <div className='heading'>
        <h1>Bonus</h1>
      </div>
      <fieldset>
        <legend>Employee Info</legend>
        <EmployeeInfo payload={bonusData} checkInputValid={checkInputValid} changeEmpInfo={changeEmpInfo}/>
      </fieldset>
      <fieldset className='bonusDates'>
        <legend>Bonus Dates</legend>

        { bonusData.bonuses.map((bonus, index) => {
          return (
          <Fragment key={'bonusDate_' + index+1}>
          <div id="bonusDate">
            <div className='date'>
              <label className='required'>
                Date
              </label>
                <input type="date" name='date' required value={bonus.date} onChange={(e) => updateBonuses(e, index)}/>
            </div>
            <div className='location'>
              <Store storeId={`bonusLocationId`} storeValue={bonus.store?.id} storeList={stores} header='Location' elementName='bonus-store' changeStoreInfo={updateBonuses} index={index} />
            </div>
            <div className='amount'>
              <label className='required'>
                Amount
              </label>
                <input type="number" name='bonus' required value={bonus.bonus} onChange={(e) => updateBonuses(e, index)}/>
            </div>
          </div>
          <div className='bonus-add-remove-btns'>
            <div className='add'>
                <button onClick={() => addBonus()}>Add Bonus</button>
            </div>
            <div className='remove'>
                <button onClick={() => removeBonus(index)}> Remove Bonus</button>
            </div>
          </div>
          </Fragment>
          )
          })

          }

      </fieldset>
      <fieldset className='bonusInfo'>
        <legend>Bonus Info</legend>
        <Comment commentsId={'bonusInfoId'} onHandleChange={setPayMissed} />
      </fieldset>
    </Fragment>
  )
}
// eslint-disable-next-line
Bonus.propTypes = {}

export default Bonus
