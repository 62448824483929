import './App.css';
import Header from './Components/Header/Header';
import RestrictedRoutes from './Components/Header/RestrictedRoutes';
import { Route, Routes, BrowserRouter as Router  } from 'react-router-dom';
import Login from './Components/Login/Login';
import Footer from './Components/Footer/Footer';
import Home from './Pages/Home';
import Backpay from './Components/Backpay/Backpay';
import Bonus from './Components/Bonus/Bonus';
import Hotel from './Components/Hotel/Hotel';
import Mileage from './Components/Mileage/Mileage';
import NewHire from './Components/NewHire/NewHire';
import PerDiem from './Components/PerDiem/PerDiem';
import Pto from './Components/Pto/Pto';
import TargetOrder from './Components/TargetOrder/TargetOrder';
import Termination from './Components/Termination/Termination';
import TimeAdjustment from './Components/TimeAdjustment/TimeAdjustment';
import Uniform from './Components/Uniform/Uniform';
import WorkTicket from './Components/WorkTicket/WorkTicket';
import CbmState from './Context/Cbm/cbmState';
import Logout from './Components/Logout/Logout';
import SubmissionSuccess from './Pages/SubmissionSuccess';
import NonMnMileage from './Components/NonMnMileage/NonMnMileage';


function App() {
  return (

    <Router>
      <CbmState>  
        <Header auth_token={localStorage.getItem("auth")} />
          
          <Routes>
              <Route path="/" element={
              <RestrictedRoutes>
                <Home />
              </RestrictedRoutes>}/>
              <Route path="/backpay" element={
              <RestrictedRoutes>
                <Backpay />
              </RestrictedRoutes>}/>
              <Route path="/bonus" element={
              <RestrictedRoutes>
                  <Bonus />
              </RestrictedRoutes>}/>
              <Route path="/hotel" element={
              <RestrictedRoutes>
                  <Hotel />
              </RestrictedRoutes>}/>
              <Route path="/mileage" element={
              <RestrictedRoutes>
                  <Mileage />
              </RestrictedRoutes>}/>
              <Route path="/nonmnmileage" element={
              <RestrictedRoutes>
                  <NonMnMileage />
              </RestrictedRoutes>}/>
              <Route path="/newhire" element={
              <RestrictedRoutes>
                  <NewHire />
              </RestrictedRoutes>}/>
              <Route path="/perdiem" element={
              <RestrictedRoutes>
                  <PerDiem />
              </RestrictedRoutes>}/>
              <Route path="/pto" element={
              <RestrictedRoutes>
                  <Pto />
              </RestrictedRoutes>}/>
              <Route path="/success" element={
              <RestrictedRoutes>
                  <SubmissionSuccess />
              </RestrictedRoutes>}/>
              <Route path="/targetorder" element={
              <RestrictedRoutes>
                  <TargetOrder />
              </RestrictedRoutes>}/>
              <Route path="/termination" element={
              <RestrictedRoutes>
                  <Termination />
              </RestrictedRoutes>}/>
              <Route path="/timeadjustment" element={
              <RestrictedRoutes>
                  <TimeAdjustment />
              </RestrictedRoutes>}/>
              <Route path="/uniform" element={
              <RestrictedRoutes>
                  <Uniform />
              </RestrictedRoutes>}/>
              <Route path="/workticket" element={
              <RestrictedRoutes>
                  <WorkTicket />
              </RestrictedRoutes>}/>
            <Route path="/logout" element={<Logout />}/>
            <Route path="/login" element={<Login />}/>
        </Routes>

          {/* <RouterProvider router={router} /> */}
          <footer className='footer'>
              <Footer />
          </footer>
        </CbmState>
    </Router>
  );
}

export default App;
