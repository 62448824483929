import React, { Fragment } from 'react'

import Store from '../Store/Store'
import Comment from '../Comment/Comment'

const EmployeePositionInfo = props => {
    return (
        <Fragment>
            <fieldset>
                <legend>Position information</legend>
                <div className='positionInfo'>
                    <div className='firstDayWorked'>
                        <label htmlFor='firstDayWorked' className='required'>First day worked:</label>
                        <input type="date" id='firstDayWorked' value={props.newHireInfo.firstDayWorked} name='firstDayWorked' required onChange={(e) => props.onHandleChange(e)}/>
                    </div>
                    <div className='numOfDays'>
                        <label htmlFor='numWorkDays' className='required'># of days:</label>
                        <input type="text" id='numWorkDays' name='numWorkDays' value={props.newHireInfo.numWorkDays} required onChange={(e) => props.onHandleChange(e)}/>
                    </div>
                    <Store changeStoreInfo={props.changeStoreInfo} storeValue={props.newHireInfo?.store?.id} storeList={props.storeList} header='Location' storeId={'newEmpId'} elementName='store' required />
                    <div className='payRate'>
                        <label htmlFor='payRate' className='required'>Payrate/hr:</label>
                        <input type="text" id='payRate' name='payRate' value={props.newHireInfo.payRate} required onChange={(e) => props.onHandleChange(e)} />
                    </div>
                    <div className='position'>
                        <label htmlFor='position' className='required'>Position:</label>
                        <select id='position' name='position' value={props.newHireInfo.position} required onChange={(e) => props.onHandleChange(e)}>
                            <option value=''>Select position</option>
                            <option value='Disinfecting'>Disinfecting</option>
                            <option value='Floor_Tech'>Floor Tech</option>
                            <option value='Floater'>Floater</option>
                            <option value='Porter'>Porter</option>
                            <option value='PS'>PS</option>
                            <option value='Site_Lead'>Site Lead</option>
                            <option value='Substitute'>Substitute</option>
                        </select>
                    </div>
                    <div className='positionNum'>
                        <label htmlFor='positionNum' className='required'>Position #</label>
                        <input type='text' name='positionNum' required value={props.newHireInfo.positionNum} onChange={(e) => props.onHandleChange(e)}/>
                    </div>
                    <div className='amountHours'>
                        <label className='required'>How many hours:</label>
                        <div>
                            <div>
                                <label htmlFor='more30'>More than 30hrs</label>
                                <input type='radio' required name='numOfHours' checked={props.newHireInfo.numOfHours === 'More than 30'} id='more30' value='More than 30' onChange={(e) => props.onHandleChange(e)} />
                            </div>
                            <div>
                                <label htmlFor='less30'>Less than 30hrs</label>
                                <input type='radio' required name='numOfHours' checked={props.newHireInfo.numOfHours === 'Less than 30'} id='less30' value='Less than 30' onChange={(e) => props.onHandleChange(e)} />
                            </div>
                        </div>
                    </div>
                    <div className='preferredLanguage'>
                        <label className='required'>Preferred lanaguage</label>
                        <div>
                            <div>
                                <label htmlFor='english'>English</label>
                                <input type='radio' required name='preferredLanguage' id='english' value='English' checked={props.newHireInfo.preferredLanguage === 'English'} onChange={(e) => props.onHandleChange(e)}/>
                            </div>
                            <div>
                                <label htmlFor='spanish'>Spanish</label>
                                <input type='radio' required name='preferredLanguage' id='spanish' value='Spanish' checked={props.newHireInfo.preferredLanguage === 'Spanish'}  onChange={(e) => props.onHandleChange(e)}/>
                            </div>
                        </div>
                    </div>
                    <div className='documentTitle'>
                        <label htmlFor='documentTitle' className='required'>Document Title</label>
                        <input type="text" name="documentTitle" value={props.newHireInfo.documentTitle} onChange={(e) => props.onHandleChange(e)} />
                    </div>
                    <div className='documentNumber'>
                        <label htmlFor='documentNumber' className='required'>Document Number</label>
                        <input type="text" name="documentNumber" value={props.newHireInfo.documentNumber} onChange={(e) => props.onHandleChange(e)} />
                    </div>
                    <div className='aNumber'>
                        <label htmlFor='aNumber'>A# if applicable</label>
                        <input type="text" name="aNumber" value={props.newHireInfo.aNumber} onChange={(e) => props.onHandleChange(e)} />
                    </div>
                    <div className='documentExp'>
                        <label htmlFor='documentExp' className='required'>Document Expiration</label>
                        <input type="date" name="documentExp" value={props.newHireInfo.documentExp} onChange={(e) => props.onHandleChange(e)} />
                    </div>
                    <div className='govIdFront file-upload'>
                        <label htmlFor='govIdFront' className='required'> Gov Id front:</label>
                        <input type='file' id='govIdFront' name='govIdFront' required onChange={(e) => props.validateFileUpload(e)}/>
                    </div>
                    <div className='govIdRear file-upload'>
                        <label htmlFor='govIdRear' className='required'>Gov Id rear:</label>
                        <input type='file' id='govIdRear' name='govIdRear' required onChange={(e) => props.validateFileUpload(e)}/>
                    </div>
                    <div className='ssnCard file-upload'>
                        <label htmlFor='ssnCard' className='required'>SSN:</label>
                        <input type='file' id='ssnCard' name='ssnCard' required onChange={(e) => props.validateFileUpload(e)}/>
                    </div>                
                    <div className='idBadge file-upload'>
                        <label htmlFor='idBadge' className='required'>Picture of ID badge:</label>
                        <input type='file' id='idBadge' name='idBadge' required onChange={(e) => props.validateFileUpload(e)}/>
                    </div>
                    <Comment payload={props.newHireInfo.comments} onHandleChange={props.onHandleChange} />
                </div>
            </fieldset>
        </Fragment>
    )
}

EmployeePositionInfo.propTypes = {}

export default EmployeePositionInfo