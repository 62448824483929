import React, { Fragment, useContext, useState } from 'react'
import './NewHire.css';
import NewEmployeeInfo from '../EmployeeInfo/NewEmployeeInfo';
import EmployeePositionInfo from '../EmployeeInfo/EmployeePositionInfo';
import { useEffect } from 'react';
import CbmContext from '../../Context/Cbm/cbmContext';
// import { updateEmptyErrorFields, updateErrorFields, removeValidErr, validateData } from '../Utils/helperFuncs';

const NewHire = ({ setPayLoad, setIdBadgeFile, setGovIdFrontFile, setGovIdRearFile, setSsnCardFile, setFormError }) => {

  const cbmContext = useContext(CbmContext);
  const { stores, portalUser, submittedData, errorCode, submitStatus, submitFailed, stateList, getStates, stateListRetrieved } = cbmContext;
  const [fieldErrors, setFieldErrors] = useState([]);
  const [page, setPage] = useState(1);
  const [selectedState, setSelectedState] = useState(null);
  const [newHireInfo, setNewHireInfo] = useState({
    hireType: '',
    employee: {
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
      address: {
        address1: '',
        address2: '',
        city: '',
        state: '',
        zip: ''
      },
      phone1: '',
      phone2: '',
      email: '',
      ssn: '',
      gender: '',
      dob: '',
    },
    firstDayWorked: '',
    numWorkDays: 0,
    store: null,
    payRate: 0,
    position: '',
    positionNum: '',
    numOfHours: '',
    preferredLanguage: '',
    comments: '',
    documentTitle: '',
    documentNumber: '',
    aNumber: '',
    documentExp: '',
    portalUser,
  })

  useEffect(() => {
     return () => {
      window.scrollTo(0,0);
    }
  }, [page])

  useEffect(() => {
    document.getElementById('')
  })
  useEffect(() => {
    return () => {
      if (selectedState === null && stateListRetrieved === false) {
        getStates();
      }
    }
  }, [selectedState, getStates, stateListRetrieved])

  useEffect(() => {
    setPayLoad(newHireInfo);
  }, [setPayLoad, newHireInfo])

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])

  useEffect(() => {
    setPayLoad(newHireInfo);
  }, [setPayLoad, newHireInfo])

  const handleAddressInfo = (e) => {
    const { name, value } = e.target
    let currentAddressInfo = {...newHireInfo};
    currentAddressInfo.employee.address[name] = value;
    setNewHireInfo(currentAddressInfo);
  }

  // const checkDataValidation = (name, value, currentData, currentErrorList) => {
  //   let newErrorList = [];
  //   if (( value === '' || value === null)) {
  //     newErrorList = updateEmptyErrorFields(name, currentErrorList);
  //     setFieldErrors(newErrorList)
      
  //   } else if (validateData(name, value, currentData) === false) {
  //     if (fieldErrors?.length > 0) {
  //       newErrorList = updateErrorFields(name, currentErrorList);
  //       console.log(newErrorList);
  //       setFieldErrors(newErrorList)
  //     } else {
  //       newErrorList = updateErrorFields(name, []);
  //       console.log(newErrorList);
  //       setFieldErrors(newErrorList)
  //     }
  //   } else if (validateData(name, value, currentData)){
  //     setFieldErrors(removeValidErr(name, currentErrorList));
  //   }

  // }

  const validateFileUpload = (e) => {
    console.log(e.target.files);
    console.log(e.target.name);
    if (e.target.files.length > 0 && e.target.name === 'idBadge') {
      setIdBadgeFile(e.target.files[0])
    } else if (e.target.files.length > 0 && e.target.name === 'govIdFront') {
      setGovIdFrontFile(e.target.files[0])
    } else if (e.target.files.length > 0 && e.target.name === 'govIdRear') {
      setGovIdRearFile(e.target.files[0])
    } else if (e.target.files.length > 0 && e.target.name === 'ssnCard') {
      setSsnCardFile(e.target.files[0])
    }
  }

  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  const onHandleChange = (e) => {
    const { name, value } = e.target;
    let currentHireInfo = {...newHireInfo};
    // checkDataValidation(name, value, currentHireInfo, [...fieldErrors])
    if (name === 'employeeFirst' ||
        name === 'employeeMiddle' ||
        name === 'employeeLast' || 
        name === 'employeeSecondLast' || 
        name === 'phone1' ||
        name === 'phone2' ||
        name === 'email' ||
        name === 'dob' ||
        name === 'ssn' ||
        name === 'gender') {
      currentHireInfo.employee[name] = value;
    } else {
      currentHireInfo[name] = value;
    }
    setNewHireInfo(currentHireInfo)
  }

  const changeStoreInfo = (e) => {
    const {value} = e.target;
    const foundStore = stores.filter(store => {
      return parseInt(value) === store.id;
    })
    setNewHireInfo({...newHireInfo, store: foundStore[0]});
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '' && submitStatus && submitFailed) {
      submittedData.get('newHireInfoJSON').text()
      .then (resp => {
        if (resp) {
          setNewHireInfo(JSON.parse(resp))
          window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
        }
      })
    } 
  }, [submittedData, setNewHireInfo, errorCode, submitFailed, submitStatus])

  return (
    <Fragment>
      <div className='heading'>
        <h1>New Hire</h1>
      </div>
      {
      page === 1 ?
        <NewEmployeeInfo 
          handleAddressInfo={handleAddressInfo}
          addressInfo={newHireInfo.employee.address}
          onHandleChange={onHandleChange}
          setNewHireInfo={setNewHireInfo}
          newHireInfo={newHireInfo}
          states={stateList}
          selectedState={selectedState}
          setSelectedState={setSelectedState}
          checkInputValid={checkInputValid}
        />
        :
        <EmployeePositionInfo 
          onHandleChange={onHandleChange}
          changeStoreInfo={changeStoreInfo} 
          storeList={stores}
          setIdBadgeFile={setIdBadgeFile}
          newHireInfo={newHireInfo}
          validateFileUpload={validateFileUpload}
        />
      }
      <div className='nextPrevBtns'>
      {
        page === 1 ?
        <div className='nextBtn'>
          <button className='newHireNextPage' onClick={() => setPage(2)}>Next page </button>
        </div>
        :
        <Fragment>
          <div className='prevBtn'>
            <button className='newHirePrevPage' onClick={() => setPage(1)}>Prev page</button>
          </div>
        </Fragment>
      }
      </div>
    </Fragment>
  )
}
// eslint-disable-next-line
NewHire.propTypes = {}

export default NewHire
