import React, { useState, useContext, useEffect, Fragment} from 'react'
import CbmContext from '../../Context/Cbm/cbmContext';
import './NonMnMileage.css';
import Comment from '../Comment/Comment';
import EmployeeInfo from '../EmployeeInfo/EmployeeInfo';
import Store from '../Store/Store';
import { updateEmptyErrorFields, updateErrorFields, validateData, removeValidErr } from '../Utils/helperFuncs';

const NonMnMileage = ({ setPayLoad, setFormError }) => {

  const cbmContext = useContext(CbmContext);
  const { stores, portalUser, submittedData, errorCode } = cbmContext;
  const [fieldErrors, setFieldErrors] = useState([])
  const [mileages, setMileages] = useState(
    [
      {
        date: '',
        location1: {},
        location2: {},
        rt_ow: ''
      }
    ]
  )
  const [mileageData, setMileageData] = useState({
    employee: {
      employeeNumber: '',
      employeeFirst: '',
      employeeMiddle: '',
      employeeLast: '',
      employeeSecondLast: '',
    },
    mileageDates: mileages,
    comments: "",
    portalUser
  })

  useEffect(() => {
    setPayLoad(mileageData);
  }, [setPayLoad, mileageData])

  useEffect(() => {
    if (fieldErrors.length > 0) {
      setFormError(true);
    } else if (fieldErrors.length === 0) {
      setFormError(false);
    }
  }, [fieldErrors, setFieldErrors, setFormError])
  
  const checkDataValidation = (name, value, currentData, currentErrorList) => {
    let newErrorList = [];
    if (( value === '' || value === null)) {
      newErrorList = updateEmptyErrorFields(name, currentErrorList);
      setFieldErrors(newErrorList)
      
    } else if (validateData(name, value, currentData) === false) {
      if (fieldErrors?.length > 0) {
        newErrorList = updateErrorFields(name, currentErrorList);
        setFieldErrors(newErrorList)
      } else {
        newErrorList = updateErrorFields(name, []);
        setFieldErrors(newErrorList)
      }
    } else if (validateData(name, value, currentData)){
      setFieldErrors(removeValidErr(name, currentErrorList));
    }
  }

  const checkInputValid = (fieldName) => {
    if (fieldErrors?.length > 0) {
      return fieldErrors?.findIndex(x => x.input === fieldName) === -1;
    } else {
      return true;
    }
  }

  const changeEmpInfo = (e) => {
    const { name, value } = e.target;
    let tempMileageData = {...mileageData}
    // checkDataValidation(name, value, tempMileageData, [...fieldErrors])
    tempMileageData.employee[name] = value;
    setMileageData(tempMileageData);
    }
    
    const filterStore = (value) => {
      let foundStore = [];
      foundStore = stores.filter(store => {
        if (parseInt(value) === store.id) {
          return store;
        } else {
          return null;
        }
      })
      return foundStore[0];
    }
    
    const updateMileages = (e, index) => {

      const { name, value, id } = e.target;
      let mileageTemp = [...mileageData.mileageDates];
      let foundStore = {};
        if (name === 'location1' || name === 'location2') {
          foundStore = filterStore(value)
          mileageTemp[index][name] = foundStore;
      } else if (id === 'rt' || id === 'ow') {
        let radioOption = name.split('_')
        radioOption = radioOption[0] + '_' + radioOption[1];
        mileageTemp[index][radioOption] = value;
    }  else {
        mileageTemp[index][name] = value;
      }
      setMileageData({...mileageData, mileagesDates: mileageTemp})
    }

  const addMileage = () => {
    const mileageTemp = [...mileageData.mileageDates];
    mileageTemp.push({date: '', location1: '', location2: '', rt_ow: ''})
    setMileages(mileageTemp);
    setMileageData({...mileageData, mileageDates: mileageTemp})
  }

  const removeMileage = (index) => {
    const mileageTemp = [...mileageData.mileageDates]
    const newMileageList = mileageTemp.filter((entry, i) => { return i !== index })
    setMileages(newMileageList);
    setMileageData({...mileageData, mileageDates: newMileageList})

  }

  const setMileageComments = (e) => {
    const {value} = e.target;
    setMileageData({...mileageData, comments: value});
  }

  useEffect(() => {
    if (submittedData !== null && errorCode !== '') {
      setMileageData(JSON.parse(submittedData));
      window.scrollTo({ top: 0, left: 0, behavior: 'smooth'})
    } 
  }, [submittedData, setMileageData, errorCode])

  return (
    <Fragment>
      <div className='heading'>
        <h1>Non MN Mileage</h1>
      </div>
      <fieldset>
        <legend>Employee Info</legend>
        <EmployeeInfo payload={mileageData} checkInputValid={checkInputValid} changeEmpInfo={changeEmpInfo} />
      </fieldset>
      <fieldset className='mileageDates'>
        <legend>Mileage date</legend>
        { mileageData.mileageDates.map((mileageDate, index) => {
          return (
          <Fragment key={'mileage_' + index} >
          <div id={'mileageDateId'} className='mileage-date-container'>
            <div className='mileageDate'>
              <div>
                <label htmlFor={`mileageDate_${index}`} className='required'>Date</label>
                <input type="date" name='date' id={`mileageDate_${index}`} value={mileageDate.date} onChange={(e) => updateMileages(e, index)}/>
              </div>
            </div>
            <div className='location'>
              <Store storeId={'location_' + index} storeValue={mileageData.mileageDates[index].location1?.id} storeList={stores} header='location 1' changeStoreInfo={updateMileages} index={index} elementName='location1' />
            </div>
            <div className='location'>
              <Store storeId={'location2_' + index} storeValue={mileageData.mileageDates[index].location2?.id} storeList={stores} header='Location 2' changeStoreInfo={updateMileages} index={index} elementName='location2'/>
            </div>
            <div className='rt-ow'>
                <label className='required'>Select RT/OW:</label> 
                <div>
                  Round trip 
                  <input type="radio" checked={mileageDate.rt_ow === 'RT'}  id='rt' name={'rt_ow_' + index} value='RT' onChange={(e) => updateMileages(e, index)} /> 
                </div>
                <div>
                  One way 
                  <input type='radio' checked={mileageDate.rt_ow === 'OW'} id='ow' name={'rt_ow_' + index} value='OW' onChange={(e) => updateMileages(e, index)}/>
                </div>
              </div>
          </div>
          <div className='mileage-add-remove-btns'>
            <div className='add'>
                <button onClick={() => addMileage()}>Add mileage</button>
            </div>
            <div className='remove'>
                <button onClick={() => removeMileage(index)}> Remove mileage</button>
            </div>
          </div>
          </Fragment>
          )
          })

          }
      </fieldset>
      <fieldset className='mileageComments'>
        <Comment commentsId={'mileageCommentsId'} payload={mileageData.comments} onHandleChange={setMileageComments} />
      </fieldset>
    </Fragment>
  )
}
// eslint-disable-next-line
NonMnMileage.propTypes = {}

export default NonMnMileage
