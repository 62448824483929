import React, { useContext, useState } from 'react'
import './Header.css';

import CbmContext from '../../Context/Cbm/cbmContext';
import CbmLogoWhite from '../../images/carlson_logo-inverted.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

const Header = props => {

  const cbmContext = useContext(CbmContext)
  const { headerLinks, auth, portalUser } = cbmContext;
  const [formListOpen, setFormListOpen] = useState(false)
  const [termListOpen, setTermListOpen] = useState(false)

  const generateTermTypes = () => {
    return (
      <div className={termListOpen ? 'term-list-open' : 'term-list-closed'} onMouseOver={() => setTermListOpen(true)} onMouseOut={() => setTermListOpen(false)}>
        <div className='quit'><a href='/?formName=termination&termForm=quit' onClick={() => localStorage.setItem('termType', 'quit')}>Quit</a></div>
        <div className='noCallNoShow'><a href='/?formName=termination&termForm=noCall' onClick={() => localStorage.setItem('termType', 'noCall')}>No Call/No Show</a></div>
        <div className='noShowFirstDay'><a href='/?formName=termination&termForm=noShowFirstDay' onClick={() => localStorage.setItem('termType', 'noShowFirstDay')}>No Show First Day</a></div>
        <div className='other'><a href='/?formName=termination&termForm=Other' onClick={() => localStorage.setItem('termType', 'Other')}>Other reason</a></div>
      </div>
    )
  }
  return (
    <div className='headerContainer'>
      <div className='header'>
        <div className='carlsonLogo'>
          <img alt="Carlson Logo" src={CbmLogoWhite} width={'100%'} />
        </div>
        <div className='headerLinks'>
          { auth &&
          <div className='formLinks' onMouseOver={() => setFormListOpen(true)} onMouseOut={() => setFormListOpen(false)}>
            <button>Form <FontAwesomeIcon icon={faAngleDown} rotation={!formListOpen ? 0 : 180} /></button>
            <div className={formListOpen ? 'list-open' : 'list-closed'} id='form-links'>
              {  headerLinks.length > 0 && headerLinks.map(link => {
                if (link?.title === 'Termination') {
                  return (
                  <div key={link?.title + 'link'} className='termForm' onMouseOver={() => setTermListOpen(true)} onMouseOut={() => {setTermListOpen(false)}}>
                    <button>{link?.title}<FontAwesomeIcon icon={faAngleDown} rotation={!termListOpen ? 270 : 90} />
                    </button>
                    {generateTermTypes()}
                  </div>
                  )  
              }
                return <div key={link?.title + 'link'} className={link?.link.toLowerCase().substring(1,link?.link.length) + 'Form'}><a href={link?.link}>{link?.title}</a></div>
              })}
            </div>
          </div>
          }
          <div className='login-admin-links'>
            <div className='loginLink'>
              { auth ? <Link to='/logout'>Logout</Link>: <Link to='/login'>Login</Link>}
            </div>
            { portalUser?.authorities?.find(authority => authority.authority === 'Admin') &&
            <div className='adminLink'>
              <a href="/admin">Admin</a>
            </div>}
          </div>
        </div>
      </div>
    </div>
  )
}

Header.propTypes = {}

export default Header